@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.root {
    @include primaryColors;
    @include rounded;
    height: 100%;
    min-width: 12rem;
    min-height: 3rem;
    white-space: nowrap;
    padding: 0.6rem 2.4rem 0.6rem 2.4rem !important;
    &:hover {
        filter: brightness(90%);
    }
}

.disabled {
    opacity: 0.5;
}
