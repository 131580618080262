@mixin paddedContainer($padding: 1.6rem) {
    padding: $padding;
    box-sizing: border-box;
}

@mixin flexRow($justify: '', $align: center, $nowrap: nowrap) {
    display: flex;
    flex-flow: row $nowrap;
    align-items: $align;
    justify-content: $justify;
}

@mixin cardTitle {
    font-size: clamp(22px, 5vw, 2rem);
}

@mixin smallFontSize {
    font-size: clamp(14px, 1vw, 16px) !important;
}

@mixin flexColumn($justify: '', $align: '', $nowrap: nowrap) {
    display: flex;
    flex-flow: column $nowrap;
    justify-content: $justify;
    align-items: $align;
}

@mixin primaryColors {
    background-color: $primaryColor !important;
    color: $primaryTextColor !important;
}

@mixin boxShadow($shadow: 1, $color: black) {
    box-shadow: 0 0 6px 2px rgba($color, $alpha: 0.1 * $shadow);
}

@mixin rounded($radius: 4px) {
    border-radius: $radius;
}

@mixin paper($padding: 1.6rem) {
    background-color: $paperColor;
    @include paddedContainer($padding);
    @include rounded;
}

@mixin boxShadow($shadow: 1) {
    box-shadow: 0 0 6px 2px rgba($color: black, $alpha: 0.1 * $shadow);
}

@mixin button {
    @include primaryColors;
    max-height: 100%;
    width: fit-content;
    // text-transform: none !important;
}

@mixin ellipsis($row: 3, $boxOrient: vertical) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: $boxOrient;
    white-space: normal;
    word-wrap: break-word;
}

@mixin flexCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}
