@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.root {
    background: $primaryColor !important;
    padding: 2px 16px !important;
    height: 100% !important;
    @include primaryColors;
    @include rounded;
    &:hover {
        filter: brightness(90%);
    }

    &:disabled {
        opacity: 0.5;
    }
}
