.container{
    display: table-cell;
    /* vertical-align: middle; */
}

.item-container{
    /* display: table-cell; */
    padding:3px;
}

.label-container{
    margin-left: 45px;
}

.line-container div{
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
}

.meter-background {
    background-color: #4c3f8f;
    border-radius: 0 10px 10px 0;
    width:70%;
}

.meter-container {
    background-image: linear-gradient(to right,#9250d5, #fe0072);
    border-radius: 0 10px 10px 0;
    height:100%;
}

.anti-meter-container {
    background-color: #4c3f8f;
    border-radius: 0 10px 10px 0;
    height:100%;
}

.value-container{
    font-weight: bold;
    margin:0 10px 0 10px;
    font-size: 18px;
    line-height: 0;
    vertical-align: baseline;
}

.index-container{
    font-size: 24px !important;
}