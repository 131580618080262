@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.root {
    @include rounded(2px);
    @include paddedContainer(4px 6px);
    box-shadow: 0 0 0px 1px rgba($color: white, $alpha: 0.2);
    width: calc(100% - 4px);
    user-select: none;
    overflow-y: auto;
    gap: 6px;
    display: inline-flex;
    position: relative;
    margin: 2px;
    flex: 1;
    overflow: auto;
    flex-wrap: wrap;

    &:hover {
        box-shadow: 0 0 0px 1px white;
    }

    &:focus-within {
        border: 0;
        box-shadow: 0 0 0 2px $primaryColor;
    }

    .input {
        flex: 1;
        min-width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        .inputRoot {
            min-width: fit-content;
            display: flex;
            width: 100%;
        }
    }

    .chips {
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
        height: fit-content;
        width: fit-content;
        overflow: auto;
        // align-items: center;
    }
}
