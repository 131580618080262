@import '../../../../styles/mixins';
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.container {
  min-height: 600px;
  padding: 24px;
  width: 100%;
  background: #14172c;
  position: relative;
  .titleContainer {
    width: 100%;
    margin-bottom: 30px;

    .title {
      font-size: 18px;
      font-family: inherit;
      font-weight: 600;
    }
  }
  .progress {
    position: absolute;
  }
  .inputContainer {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    .label {
      font-size: 16px;
      max-width: 135px;
    }
    .inputBox {
      .inputItem {
        display: flex;
        align-items: center;
        .marginDense {
          margin: 0 !important;
        }
        .pointer {
          cursor: pointer;
        }

        .inputMarginDense {
          margin: 0 !important;
          padding: 8px !important;
        }
      }
      .exampleLabel {
        margin-top: 6px;
        font-size: 12px;
        .blue {
          color: rgb(105,138,205);
          text-decoration: underline;
        }
      }
      .error {
        margin-top: 4px;
        font-size: 14px;
        color: #ff4e4e;
      }
    }
  }
  .info {
    display: flex;
    margin-top: 30px;
    svg {
      width: 16px;
      height: 16px;
    }
    .text {
      display: flex;
      flex-flow: column;
      margin-left: 8px;
      font-size: 14px;
    }
  }
  .button {
    float: right;
    font-size: 14px;
    height: auto;
    margin-top: 40px;
  }
}

.root {
  width: 757px;
  .chips {
    flex-wrap: wrap;
    gap: 3px;
    height: fit-content;
    width: fit-content;
    overflow: auto;
  }
}