@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  border-radius: 6px;
  border: 1px solid #FFFFFF;
  border-top: 8px solid #FFFFFF;
  background-color: #282A3F;
  .title {
    padding: 12px 20px;
    border-bottom: 0.5px solid #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    img {
      cursor: pointer;
      filter: brightness(0) invert(1);
    }
  }
  .content {
    padding: 20px 16px;
    .label {
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 40px;
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .cancel {
        margin-right: 17px;
        font-family: 'Open Sans';
        font-size: 14px;
        line-height: 19px;
        color: #EA3385;
        cursor: pointer;
      }
      .button {
        height: 30px;
        color: #FFFFFF;
        font-size: 14px;
        border-radius: 2px;
      }
    }
  }
}

.modal {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(56, 56, 82, 0.6) ;
}