@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.container {
    border-radius: 5px;
    width: 100%;
    height: 270px;
    overflow: scroll;

    .clickable {
        cursor: pointer;
    }

    .tableContainer {
        padding: 10px 20px 0;
        overflow-x: hidden;
    }

    .tableContent {
        padding: 0 16px;
        box-sizing: border-box;
    }

    .iconUp {
        transform: rotate(180deg);
        cursor: pointer;
    }

    .sortIcon {
        margin-left: 6px !important;
    }

    .tableData {
        border-bottom: 1px solid #000000 !important;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        padding: 0px 0px 0px 10px;
        color: #FFFFFF;
    }

    .headCell {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        padding: 0px 0px 0px 10px;
        border-bottom: 1px solid #FFFFFF !important;
        color: #FFFFFF;

        &.active {
            color: #ea3385;

            svg {
                color: #ea3385 !important;
            }
        }
    }

    .row:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}