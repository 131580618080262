@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.dashboardItem {
    background-color: #383852;
    border-radius: 4px;
    min-height: 210px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.title {
    font-size: clamp(13px, 1.5rem, 24px);
    font-weight: 600;
    flex: 1;
}

.errorContent {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.dashboardItemSkeleton {
    background-color: #383852;
    border-radius: 4px;
    min-height: 210px;
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
}

.dashboardItem:last-of-type {
    margin-right: 0;
}

.dashboardItemTitle {
    border-bottom: 1px solid #000000;
    justify-content: space-between;
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.removeBorder {
        border-bottom: unset;
    }
}

.dashboardItemTitle h4 {
    margin: 0;
    white-space: break-spaces;
}

.actionTrigger {
    cursor: pointer;
    color: #ea3385;
    line-height: 1.4rem;
    margin-right: 20px;
    white-space: nowrap;
}

.skeletonContent {
    // display: flex;
    // align-items: center;

    width: 100%;
    // padding: 12px;
    height: 100%;

    .circle {
    }

    .texts {
        width: 100%;
        align-items: flex-start;
        text-align: end;
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    // justify-content: center;
}

.loaderContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flexRow {
    display: flex;
    gap: 3px;
    align-items: center;
}
