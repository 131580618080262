:root {
    --background-color: var(--border-seperator-color);
    --seconday-color: var(--selected-container-color);
    --background-color-shadow: #632d55;
}

.flatpickr-calendar {
    background: var(--background-color);
    border-radius: 4px;
    box-shadow: 0px 5px 30px rgba(21, 23, 44, 0.5);
    margin-top: 0.6rem;
}

.flatpickr-day {
    margin: 0 !important;
    font-weight: 400 !important;
}

.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
span.flatpickr-weekday {
    background: transparent;
}

.flatpickr-months .flatpickr-month {
    margin-bottom: 0.8rem;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: var(--seconday-color);
    border-color: var(--seconday-color);
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day:focus,
.flatpickr-day.nextMonthDay:focus {
    background: var(--background-color-shadow);
    border-color: var(--background-color-shadow);
}

.flatpickr-day.inRange {
    -webkit-box-shadow: -5px 0 0 var(--background-color-shadow), 5px 0 0 var(--background-color-shadow);
    box-shadow: -5px 0 0 var(--background-color-shadow), 5px 0 0 var(--background-color-shadow);
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    -webkit-box-shadow: -10px 0 0 var(--seconday-color);
    box-shadow: -10px 0 0 var(--seconday-color);
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.numInputWrapper {
    font-weight: 400;
    font-size: 1.8rem;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
    fill: var(--seconday-color);
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    -webkit-box-shadow: -10px 0 0 var(--seconday-color);
    box-shadow: -10px 0 0 var(--seconday-color);
}

.flatpickr-day.week.selected {
    -webkit-box-shadow: -5px 0 0 var(--seconday-color), 5px 0 0 var(--seconday-color);
    box-shadow: -5px 0 0 var(--seconday-color), 5px 0 0 var(--seconday-color);
}

.flatpickr-day.today {
    border-color: var(--seconday-color);
}

.numInputWrapper span.arrowUp,
.numInputWrapper span.arrowDown {
    border: none;
}
