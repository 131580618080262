@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.header {
    display: flex;
    gap: 16px;
    align-items: center;

    .sectionTitle {
        font-size: 18px;
        font-weight: 600;
    }
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 24px;
}

.threatGroupTable {
    margin-left: auto;
    margin-bottom: auto;
    table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;
    }

    th {
        font-size: 14px;
        padding-bottom: 5px;
        border-bottom: 0.5px solid var(--border-seperator-color-light);
    }

    table > tr > td:nth-child(1) div {
        position: relative;
        height: 100%;
    }

    table > tr > td:nth-child(1) div:after {
        content: '';
        background-color: var(--color);
        border: 0.5px solid var(--border-seperator-color-light-darker);
        box-sizing: border-box;
        border-radius: 2px;
        display: inline-block;
        margin-right: 11px;
        height: 14px;
        width: 14px;
        position: absolute;
        left: 0;
        top: 0;
    }

    table > tr > td:nth-child(1) div:before {
        width: 23px;
        display: inline-block;
        content: '';
    }

    td {
        position: relative;
        padding: 8px;
        font-size: 1.4rem;
        border-bottom: 0.5px solid var(--border-seperator-color);
        box-sizing: border-box;
    }

    tr:last-child td {
        border-bottom: 0;
    }

    ul {
        padding: 0;
        list-style: none;
        margin: 0;
    }

    li {
        display: flex;
        font-size: 1.3rem;
        border-radius: 4px;
        gap: 6px;
    }

    li:first-child {
        margin-top: 0;
    }

    li.unprotected {
        background-color: rgba(230, 230, 230, 0.1);
    }

    li:before {
        content: '•';
        margin-right: 2px;
    }

    li > div:nth-child(1) {
        flex: 1;
    }

    li > div:nth-child(2) {
        font-weight: 600;
    }

    .threatGroupTitle {
        white-space: nowrap;
    }

    .topThreatTitle {
        white-space: nowrap;
    }

    .threatGroupsTitleContainer {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .threatGroupLegend {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, auto);
        justify-content: flex-start;
        align-items: center;

        .legentColor {
            grid-row: 1;
            grid-column: 1;
        }

        .legendTitle {
            display: flex;
            align-items: center;
            grid-row: 1;
            grid-column: 2;
        }

        .legendValues {
            grid-row: 2;
            grid-column: 2;
        }
    }

    .flexRow {
        display: flex;
        align-items: center;
    }

    .flexColumn {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .topThreatTypeList {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .topThreatItem {
            display: flex;
            gap: 6px;
            white-space: nowrap;

            &::before {
                content: '•';
                margin-right: 2px;
            }
        }
    }
}

.container {
    display: flex;
    height: 30rem;
    max-height: 400px;
    padding: 12px;
    box-sizing: border-box;
    align-items: center;

    .barGraph {
        height: 100%;
        flex: 1;
    }
}

.title {
    display: inline-flex;
    gap: 12px;
    align-items: center;

    .selector {
        display: flex;
        flex: 1;

        .option {
            width: 112px;
            display: flex;
            border: 1px solid #ffffff;
            height: 30px;
            font-size: 1.3rem;
            text-transform: uppercase;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;
            align-items: center;
            justify-content: center;

            &:last-child {
                border-bottom-right-radius: 2px;
                border-top-right-radius: 2px;
            }

            &:first-child {
                border-bottom-left-radius: 2px;
                border-top-left-radius: 2px;
            }
        }

        .selected {
            background: white;
            color: $primaryColor;
        }
    }

    .divider {
        align-self: stretch;
    }

    .subtitle {
        font-size: 14px;
        font-weight: 400;
    }
}
