@import '../../../../styles/mixins';
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.root {
  background-color: #14172c;
  min-height: 750px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  padding: 2rem;
  box-sizing: border-box;
  gap: 16px;
  .title {
    font-size: 18px;
    font-family: inherit;
    font-weight: 600;
  }
  .loading {
    width: 100%;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    display: flex;
    flex-flow: column;
    .row {
      display: flex;
      align-items: center;
      margin-top: 6px;
      .button {
        font-size: 16px;
        color: $primaryColor;
        cursor: pointer;
        text-transform: uppercase;
        &.disabled {
          color: #74315B;
        }
      }
      .text {
        font-size: 14px;
        margin-bottom: 5px;
      }
      .select {
        font-size: 14px;
        width: 200px;
        height: 35px;
      }
      &.generate {
        margin-left: 20px;
        margin-top: 18px;
      }
      &.disabled {
        color: #74315B;
        pointer-events:none;
      }
    }
  }
}