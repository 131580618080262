@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: clamp(13px, 1.5rem, 24px);
  font-weight: 600;
  flex: 1;
  &.padding {
    padding-left: 12px;
    padding-bottom: 8px;
  }
}