@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/colors';

.campaigns {
    grid-row: 1;
    grid-column: 1/14;
    margin: 0;
}

.skeletonLoader {
    min-height: 220px;
}

.invalidVisits {
    display: flex;
    gap: 1rem;
}

.invalidClicks {
    width: 100px;
}

.source {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    display: flex;
    gap: 0.5rem;
    max-width: 150px;
}

.scaleBar {
    min-width: 150px;
}

.invalidUsers {
    /* width: 100px; */
}

.replacedVisits {
    width: 120px;
}

.campaign {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}
