@import '../../../../styles/mixins';
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.root {
    background-color: #14172c;
    min-height: 750px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    padding: 2rem;
    box-sizing: border-box;
    gap: 2.7rem;

    .title {
        font-size: 18px;
        font-family: inherit;
        font-weight: 600;
    }

    .secondaryTitle {
        font-size: 1rem;
    }

    .content {
        display: flex;
        flex-direction: column;
        background: #282b3f;
        padding: 20px;
        border-radius: 5px;
        gap: 1.5rem;

        label {
            font-size: 14px;
        }

        .inputsWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1.5rem;
            flex-wrap: wrap;
        }

        .selectWrapper > div {
            margin: 0;
        }

        .checkboxWrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.4rem;
        }

        .checkbox {
            padding: unset !important;
        }

        .selectWrapper {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;
        }

        footer {
            display: flex;
            justify-content: space-between;
            .save {
                display: flex;
                align-items: center;
                gap: 0.4rem;
            }
        }

        .button {
            font-size: 1.3rem;
            color: $primaryColor;
            cursor: pointer;
            text-transform: uppercase;
        }
    }

    .disabled {
        color: #74315b;
        pointer-events: none;
    }

    .inputContainer {
        margin: unset;
    }

    .exclamationIcon {
        height: 1.5rem;
        svg {
            width: 1.5rem;
        }
    }

    .error {
        display: flex;
        align-items: center;
        gap: 0.4rem;
        color: red;
        height: 1rem;
        svg {
            fill: red;
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    .inputError {
        font-size: 1.5rem;
        input {
            color: red;
            border: 1px solid red;
        }
    }
}
