@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
  background-color: #383852;
  width: 100%;
  height: 60px;
  display: flex;
  box-shadow: 0 4px 30px 0 #15172c;
  align-items: center;
  position: absolute;
  top: 0;
  color: #ffffff;
  padding: 18px 20px;
  box-sizing: border-box;
  font-family: Open Sans;
  .userContainer {
    position: absolute;
    display: flex;
    right: 20px;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    img {
      margin-right: 8px;
    }
    .dropdown {
      margin-left: 5px;
    }
  }
  .actionsContainer {
    background: #282a3f;
    border: 0.5px solid #ffffff;
    box-shadow: 0 5px 30px rgba(21, 23, 44, 0.5);
    border-radius: 2px;
    padding: 9px 13px;
    box-sizing: border-box;
    position: absolute;
    width: 133px;
    z-index: 1;
    right: 20px;
    top: 55px;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #FFFFFF;
    img {
      margin-right: 8px;
    }
  }
}