@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.visitsSummaryAndPageViewsContainer {
    display: flex;
    width: 100%;
    gap: 16px;
}

.visitSummaryContent {
    display: flex;
    gap: 3px;
    width: 100%;
}

.totalValues {
    flex: 1;

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .values {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-content: center;
            gap: 6px;

            .value {
                display: flex;
                align-items: center;
                gap: 8px;
            }
        }
    }
}

.item {
    position: relative;

    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(28px, auto));
        grid-template-rows: auto 1fr;
        justify-content: flex-start;
        padding: 12px;
        position: absolute;
        top: calc(40% - 12px);
        .color {
            grid-row: 1;
            grid-column: 1;
            display: flex;
            align-items: center;
        }

        .numbersWrraper {
            grid-row: 1;
            grid-column: 2;
            height: 100%;
            display: flex;

            // transform: translate(0, 40%);
        }

        .numbers {
            display: inline-block;

            .value {
                font-size: 3rem;
                font-weight: 600;
            }

            .percentage {
                font-size: 16px;
                font-weight: 400;
                margin-left: 6px;
            }
        }

        .outOfTotal {
            grid-row: 2;
            grid-column: 2;
            display: flex;
            gap: 6px;
            line-height: 18px;
        }
    }
}
