.container {
    display: flex;
    width: 100%;
}

.container path {
    fill: #fff;
}

.item {
    flex: 1;
    max-width: 300px;
    background: var(--container-color);
    box-sizing: border-box;
    border-radius: 0.4rem 0.4rem 0 0;
    position: relative;
    cursor: pointer;
    padding: 1rem;
    box-sizing: border-box;
    border: 4px solid transparent;
    border-bottom: 4px solid var(--container-shadow-color);
}

.icon {
    right: calc(100% + 1rem);
    top: 0;
    height: 2.4rem;
    position: absolute;
}

.item.current {
    border: 4px solid var(--selected-container-color);
    border-radius: 0.4rem;
}

.container .item:nth-child(1) {
    margin-left: 0;
}

.container .item:last-child {
    margin-right: 0;
}

.item h3.not-visible {
    font-size: 1.3rem;
    margin: 0 0 1.1rem 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    visibility: hidden;
    white-space: nowrap;
}

.item h3:not(.not-visible) {
    font-size: 1.3rem;
    margin: 0 0 1.1rem 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    float: left;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.paid-organic {
    height: 3rem;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    justify-content: center;
    width: 100%;
}
.paid-organic div:nth-child(1),
.paid-organic div:nth-child(3) {
    white-space: nowrap;
}

.blocking-since {
    height: 2rem;
    font-size: 13px;
    text-align: center;
    margin-top: 3px;
}

.seperator {
    height: 100%;
    width: 1px;
    margin: 0 1.1rem;
    background: var(--container-shadow-color);
}

.item:not(.current):after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    height: 0.3rem;
    width: 100%;
    background: var(--container-shadow-color);
}

.item.current:after {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 1.1rem solid rgba(234, 51, 133, 0);
    border-top-color: var(--selected-container-color);
    margin-left: -0.3rem;
}
