@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.selectContainer {
    width: inherit;
    background: #282b3f;
    height: inherit;
    cursor: pointer;
    // font-family: 'Open Sans', sans-serif;
    display: flex !important;
    align-items: center;
    position: relative;
    min-width: 100px;
    min-height: 25px;
    color: #fe0072;
    position: relative;
}

.selectPopup {
    position: absolute;
    top: 100%;
    z-index: 99;
    cursor: pointer;
    background: #282b3f;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column !important;
    gap: 3px;
}

.selectOption {
    font-family: 'Open Sans', sans-serif;
    display: flex !important;
    align-items: center;
    padding: 4px 8px;
    font-size: 12px;
    height: 18px;
    box-sizing: border-box;

    &:hover {
        background: rgb(56, 56, 81);
        color: #fe0072;
    }
}

.icon {
    position: absolute;
    right: 2px;
    top: 1px;
}

.label {
    position: absolute;
    left: 8px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}
