@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.root {
    margin: 0 !important;
}

.fullWidth {
    width: 100%;
}
