@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.container {
    background-color: #15172c;
    width: 238px;
    height: calc(100vh);
    box-shadow: 5px 0 30px rgba(21, 23, 44, 0.5);
    display: flex;
    flex-flow: column;

    .selectedNameContainer {
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon {
        width: 107px;
        margin-left: 16px;
        margin-top: 19.5px;
    }

    .products {
        width: 100%;
        margin-top: 28px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .selectContainer {
            position: relative;

            .select {
                width: 198px;
                height: 44px;
                border: 1px solid #ea3385;
                border-radius: 4px;
                color: #ffffff;
                padding: 12px 4px 12px 8px;
                cursor: pointer;
                font-size: 14px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .label {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;

                    img {
                        margin-right: 12px;
                    }
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                }

                &.open {
                    background-color: #383852;
                }

                svg {
                    height: 16px;
                }
            }

            .dropdown {
                max-height: 300px;
                overflow: auto;
                position: absolute;
                z-index: 1;
                background-color: #383852;
                top: calc(100% - -5px);
                font-size: 14px;
                left: 0;
                width: 238px;
                padding: 10px 0 0 13px;
                border: 1px solid #ea3385;
                border-radius: 4px;
                color: #ffffff;

                .menuItem {
                    display: flex;
                    font-weight: 400;
                    margin-bottom: 25px;
                    color: #ffffff;
                    cursor: pointer;
                    white-space: nowrap;

                    &:hover {
                        color: #ea3385;
                    }

                    &.disabled {
                        cursor: unset;
                        color: #788397;
                    }

                    &:last-child {
                        margin-bottom: 10px;
                    }

                    .help {
                        background: rgba(255, 255, 255, 0.2);
                        border-radius: 2px;
                        height: 18px;
                        width: 18px;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        margin-left: 6px;
                    }
                }
            }
        }

        .pages {
            margin-top: 20px;
            padding: 0 8px;

            .link {
                text-decoration: unset;
                color: #ffffff;
                border-radius: 4px;
                height: 44px;
                width: 198px;
                display: flex;
                align-items: center;
                font-size: 14px;
                padding: 12px;
                box-sizing: border-box;
                margin-bottom: 4px;

                &.isSelected {
                    background-color: rgba(255, 255, 255, 0.2);
                    font-weight: 600;
                }

                &:hover {
                    background-color: rgba(255, 255, 255, 0.2);
                }

                .componentIcon {
                    margin-right: 15px;
                }
            }
        }
    }
}
