@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.viewConfigBtn {
    height: 24px;
    & input {
        font-size: 12px;
        height: 100%;
        outline: none;
    }
}
