@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
}

.container {
    min-height: 15rem;
    min-width: 25rem;
    background-color: #14172c;
    padding: 2.5rem;
    display: inline-block;
}

.buttonsContainer {
    display: flex;
    align-items: center;
    float: right;
    margin-top: 3rem;
}

.title {
    font-size: 1.6rem;
    line-height: 3rem;
}

.button {
    margin-left: 15px;
}