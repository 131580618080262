@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.root {
  margin-right: 6px;
  margin-left: 0;
  padding: 0;
  font-size: 14px !important;
}
.label {
  font-size: 14px !important;
}