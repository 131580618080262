@import '../../../../styles/mixins';
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.container {
    font-size: 16px;
    padding: 24px;
    width: 100%;
    background: #14172c;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .titleContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .updating {
            display: flex;
            gap: 6px;
            font-size: 12px;
            align-items: center;
        }

        .title {
            font-size: 18px;
            font-family: inherit;
            font-weight: 600;
        }
    }

    .integrationsContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .title {
            font-size: 16px;
            display: flex;
            grid-gap: 6px;
            gap: 6px;
            align-items: center;
            font-weight: 600;
        }
    }

    .secretKey {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-left: 18px;
        margin-top: 6px;
        margin-bottom: 18px;

        .title {
            text-transform: none;
            white-space: nowrap;
            font-size: 22px;
            display: flex;
            align-items: center;
            gap: 6px;
        }

        .row {
            display: flex;
            gap: 3rem;
            align-items: flex-end;

            .button {
                color: $primaryColor;
                cursor: pointer;
                text-transform: uppercase;
            }

            .label {
                font-size: 12px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 30%;
                padding: 0 0.3rem 0.3rem 0;
            }

            .gaButtonsContainer {
                padding: 0 !important;

                .gaButton {
                    flex-basis: 50%;
                    background: inherit;
                    outline: none;
                    font-size: 14px;
                    color: #ffffff;
                    border: none;
                    cursor: pointer;
                    padding: 0 !important;
                    height: 100%;

                    &:not(.activeBtn):hover {
                        background: rgba(255, 255, 255, 0.2);
                        color: #EA3385;
                        border-radius: 3px;
                    }
                }

                .activeBtn {
                    background: #ffffff;
                    color: #EA3385;
                    border-radius: 3px;
                }
            }

            .textContainer {
                color: #ffffff;
                background-color: var(--container-color);
                border-radius: 4px;
                line-height: 1.75;
                padding: 0.6rem 1.2rem;
                width: 100%;
                height: 70%;
                display: flex;
                align-items: center;
                border: none;
                outline: none;
                justify-content: space-between;

                .text {
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    color: #ffffff;
                }

                .icon {
                    display: flex;
                    margin-left: 0.6rem;
                    cursor: pointer;
                    height: 24px;
                }
            }
        }

        .copyBtnContainer {
            justify-content: center;
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: 1rem;
        }
    }

    .disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    .generateButton {
        align-items: center;
        gap: 0 !important;
        margin-top: 12px;
    }

    .dFlexCol {
        height: 3.6em;

        .measurement {
            margin-top: 0.3rem;
        }
    }

    .dFlexRow {
        display: flex;
    }

    .sideIcons {
        display: flex;
        align-items: center;
        margin-top: 12px;
    }

    .smallInput {
        width: 14% !important;
    }

    .mediumInput {
        width: 24% !important;
    }

    .largeInput {
        width: 42% !important;
    }

    .error {
        white-space: nowrap;
        color: red;
        font-size: 14px;
    }

    .gaAddButton {
        margin-top: 12px;
    }

    .createContainer {
        margin-bottom: 2.4rem;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
    }
}