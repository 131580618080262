@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.container {
  width: 100%;
  padding: 16px 16px 0 16px;
  .wrapper {
    position: relative;
    display: flex;
    box-sizing: border-box;
    padding-bottom: 5px;
    border-bottom: 0.5px solid #FFFFFF;
    .headCell {
      flex: 1;
      display: flex;
      font-family: inherit;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;
      .sortIcons {
        position: relative;
        margin-left: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.pink {
        color: #EA3385;
      }
      &.rotate {
        .sortIcons {
          img {
            transform: rotate(180deg);
          }
        }
      }
      &:nth-child(1) {
        flex: 5;
      }
      &:nth-child(2) {
        flex: 4;
      }
      &:nth-child(3) {
        flex: 5;
      }
      &:nth-child(4) {
        flex: 5;
      }
      &:nth-child(5) {
        flex:4;
      }
      &:nth-child(6) {
        flex:3;
      }
      &:nth-child(7) {
        flex: 2;
      }
    }
    .scheduleHeadCell {
      flex: 1;
      display: flex;
      font-family: inherit;
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      align-items: center;
      white-space: nowrap;
      cursor: pointer;
      .sortIcons {
        position: relative;
        margin-left: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.pink {
        color: #EA3385;
      }
      &.rotate {
        .sortIcons {
          img {
            transform: rotate(180deg);
          }
        }
      }
      &:nth-child(1) {
        flex: 3;
      }
      &:nth-child(2) {
        flex: 3;
      }
      &:nth-child(3) {
        flex: 4;
      }
      &:nth-child(4) {
        flex: 3;
      }
      &:nth-child(5) {
        flex:2;
      }
      &:nth-child(6) {
        flex:4;
      }
      &:nth-child(7){
        flex: 2;
      }
    }
  }
}