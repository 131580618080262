@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.root {
    margin: 0 !important;
    min-width: 190px;
}
.input {
    text-align: center !important;
    padding: 0 6px !important;
    box-sizing: border-box  !important;
    height: 30px !important;
    border: 1px solid #FFFFFF  !important;
    border-radius: 2px  !important;
    font-size: 14px !important;
}

.fullWidth {
    width: 100% !important;
    flex: 1;
}
