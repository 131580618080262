@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.root {
    background-color: $seperatorColor;
    min-height: 100%;
    min-width: 1px;
    justify-self: stretch;
    align-self: stretch;
    margin: 0 1rem;
}
