@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

$padding: 16px;

.title {
    display: flex;
    gap: 12px;
    justify-content: space-between;

    .setUrls {
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 16px;
        font-weight: normal;

        .text {
            cursor: pointer;
            color: $primaryColor;
        }
    }
}

.content {
    display: flex;

    .divider {
        padding: $padding 0;
        box-sizing: border-box;
    }

    .summary {
        display: flex;
        flex-direction: column;

        padding: $padding;
        box-sizing: border-box;
        width: 280px;

        .title {
            margin-top: 16px;
            font-size: 1.5rem;
            font-weight: 600;
        }

        .interceptionsDataRow {
            display: flex;
            flex-direction: column;

            margin-top: $padding/ 2;

            .value {
                font-size: clamp(30px, 2.8rem, 36px);
                font-weight: 600;
            }

            &:last-child {
                margin-bottom: 16px;
            }
        }
    }

    .legend {
        display: flex;
        gap: 3px;
        align-items: center;

        .legendTitle {
            font-size: clamp(14px, 1.3rem, 16px);
        }
    }

    .icon {
        min-width: 14px;
    }

    .intercetptionsTable {
        padding: $padding 16px;
        flex: 1;
        overflow: hidden;

        .title {
            margin-top: $padding;
            font-size: 1.5rem;
            font-weight: 600;
        }

        .tableHeaderCell {
            background: transparent !important;
            border-bottom: 1px solid white;
            white-space: nowrap;

            .cellContent {
                display: flex;
                gap: 6px;
                align-items: center;
                font-weight: 600;
            }
        }

        .url {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 800px;
            width: 100%;
            direction: rtl;
        }

        .invalidPerPolicyVSTotal {
            display: flex;
            gap: 16px;
            align-items: center;

            .value {
                margin-left: auto;
                display: flex;
                gap: 3px;
                .perPolicy {
                    color: #ea3385;
                    margin-left: auto;
                }
                .total {
                    color: white;
                }
            }

            .bar {
                background: #ea3385;
                border-radius: inherit;
                height: 9px;
                border-radius: 4px;
            }
        }
    }
}

.setUrlDialog {
    width: 600px;
    height: 400px;
    background: #282a3f;
    border: 1px solid white;
    border-radius: 6px;
    border-top: 8px solid white;
    display: flex;
    flex-direction: column;

    .title {
        padding: 12px 20px;
        border-bottom: 1px solid white;
        font-weight: 600;
        font-size: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .textArea {
        padding: 22px 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 3px;
        font-size: 18px;
        overflow: auto;

        .inputElement {
            padding: 0;
        }
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 6px;
        padding: 0 20px 22px;

        .cancel {
            background: transparent !important;
            color: $primaryColor !important;
        }
    }
}
