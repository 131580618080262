@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/colors';

.countries {
    margin: 0;
    margin-right: 1.5rem;
}

.skeletonLoader {
    min-height: 320px;
}

.heatMap {
    margin: 0;
}

.scaleBar {
    width: 50px;
    justify-self: end;
}

.country {
    display: flex;
    align-items: center;
    gap: 8px;
}

.countryName {
    width: 100%;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.countryFlag {
    display: flex;
    gap: 8px;
    align-items: center;
}
.invalidClicks {
    display: flex;
    flex: 1;
    gap: 16px;
}
