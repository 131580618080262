:root {
    --window-xl: 1920;
    --window-lg: 1600;
    --window-md: 1366;
    --window-sm: 1280;
    --initial-font-size: 12px;
    --font-size-sizer-devider: 1.2;
    --current-window-size: var(--window-xl);

    --container-color: #383852;
    --selected-container-color: #ea3385;
    --container-shadow-color: #15172c;
    --border-seperator-color: #282a3f;
    --border-seperator-color-light: #fff;
    --border-seperator-color-light-darker: #e6e6e6;
}

@media screen and (min-width: 1919px) {
    :root {
        --current-window-size: var(--window-xl);
        --font-size: var(--initial-font-size);
    }
}

@media screen and (min-width: 1599px) and (max-width: 1919px) {
    :root {
        --current-window-size: var(--window-lg);
        --font-size: calc(var(--initial-font-size) / var(--font-size-sizer-devider));
    }
}

@media screen and (min-width: 1365px) and (max-width: 1599px) {
    :root {
        --current-window-size: var(--window-md);
        --font-size: calc(var(--initial-font-size) / var(--font-size-sizer-devider) / var(--font-size-sizer-devider));
    }
}

@media screen and (max-width: 1365px) {
    :root {
        --current-window-size: var(--window-sm);
        --font-size: calc(var(--initial-font-size) / var(--font-size-sizer-devider));
    }
}

$screenXL: 1920px;
$screenLG: 1600px;
$screenMD: 1366px;
$screenSM: 1280px;
$dashboardSeperator: 24px;
