.step-container {
    display: inline-block;
    text-align: left;
    position: relative;
}

.step-container-clickable {
   cursor: pointer;
}

.step-container > div {
    display: inline-block;
    font-size: 1.2rem !important;
}

.step-container .content {
    margin-top: 10px;
    vertical-align: top;
}

.step-container .arrow {
    position: absolute;
    right: -40px;
    /* left:73%; */
}

.arrow-top-fix {
    background: #0b0c12;
    z-index: 10;
    position: absolute;
    right: 0;
    width: 11px;
    height: 34px;
    transform: rotate(135deg);
    top: -15px;
}

.arrow-bottom-fix {
    background: #0b0c12;
    z-index: 10;
    position: absolute;
    right: 0;
    width: 11px;
    height: 30px;
    transform: rotate(-135deg);
    bottom: -13px;
}
