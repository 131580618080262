@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    .countries {
        margin: 0;
        display:flex; 
        flex-direction:row;
    }
}
