.container {
    margin: 0 auto;
    opacity: 0;
    z-index: 11;
    position: relative;
    padding: 10px;
    margin-top: 100px;
}

.close-dialog {
    position: absolute;
    right: 30px;
    top: 34px;
    font-size: 30px;
    cursor: pointer;
}

.content-container {
    /* margin: 50px 30px 50px 30px; */
}

.footer-button {
    margin: 10px;
    width: 100px;
}
