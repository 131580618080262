@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.root {
    background: #383852;
    box-shadow: 0px 4px 30px #15172c;
    min-height: 64px !important;
    padding: 0 2.4rem;
    color: #fff;
    position: sticky;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
}

.title {
    font-size: 30px;
    margin: 0 !important;
    padding: 0 !important;
}

.rightContainer {
    display: flex;
    width: 122px;
    justify-content: flex-end;
    height: 24px;
    line-height: 24px;
}

.headerContent {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: flex-end;
}

.profileName {
    margin-left: 8px;
    cursor: pointer;
    margin-top: 4px;
}

.profileArrow {
    margin-left: 5px;
    cursor: pointer;
}
