@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.root {
    display: grid;
    grid-template-rows: auto auto;
    justify-items: center;
}

.logo {
    width: 80px;
}
