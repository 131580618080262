@import '../../../../styles/mixins';
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.container {
    font-size: 16px;
    padding: 24px;
    width: 100%;
    background: #14172c;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .titleContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .updating {
            display: flex;
            gap: 6px;
            font-size: 12px;
            align-items: center;
        }

        .title {
            font-size: 18px;
            font-family: inherit;
            font-weight: 600;
        }
    }

    .secretKey {
        display: flex;
        flex-direction: column;
        margin: 18px 0;
        gap: 12px;
        margin-left: 18px;

        .title {
            text-transform: none;
        }

        .row {
            display: flex;
            align-items: center;
            .button {
                color: $primaryColor;
                cursor: pointer;
                text-transform: uppercase;
            }
        }
        .buttonGroupContainer {
            height: 4rem;
            align-items: center;
            gap: 16px;
            display: flex;
            gap: 1rem;

            .textContainer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: var(--container-color);
                min-width: 30rem;
                border-radius: 4px;
                line-height: 1.75;
                padding: 0.6rem 2.4rem 0.6rem 1.2rem;

                .text {
                    font-size: 14px;
                    color: #ffffff;
                    display: flex;
                    &.channel {
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        width: 140px;
                        display: inline-block;
                        white-space: nowrap;
                    }
                }
                &.channel {
                    margin-left: 12px;
                    margin-right: 12px;
                    min-width: 20rem;
                }
                &.apiKey {
                    margin-left: 12px;
                    width: 380px;
                }
            }

            .copyBtnContainer {
                justify-content: center;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 1rem;
            }
        }
    }

    .error {
        font-size: 12px;
        color: red;
        margin-top: 3px;
    }

    .urls {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 6px;
        overflow-y: auto;
        margin-left: 18px;

        .title {
            grid-column: 1;
            grid-row: 1;
            display: flex;
            align-items: center;
            gap: 6px;
        }

        .includeSubDomains {
            grid-column: 2;
            grid-row: 1;
            display: flex;
            align-items: center;
            gap: 6px;
        }

        .input {
            grid-column: 1;
            display: flex;
            align-items: center;
            height: auto;

            .inputWrapper {
                width: 100%;
            }

            .marginDense {
                margin: 0 !important;
            }

            .pointer {
                cursor: pointer;
            }

            .inputMarginDense {
                margin: 0 !important;
                padding: 8px !important;
            }

            .standart {
                padding: 0;
            }

            .outlined {
                padding: 0;
            }
            .actions {
                display: flex;
                padding: 0 0.5rem;
                align-items: center;
                width: 50px;
                box-sizing: border-box;
            }
        }

        .toggler {
            grid-column: 2;
            grid-row: auto;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            padding: 0 12px;
        }

        .addUrlButton {
            color: $primaryColor;
        }

        .divider {
            display: flex;
            align-items: center;
            grid-column: 1/3;
            margin: 8px 0;

            .hr {
                width: 100%;
            }
        }

        .url {
            grid-column: 1;
            grid-row: auto;
            display: flex;

            .actions {
                display: flex;
                padding: 0 0.5rem;
                align-items: center;
                width: 50px;
                box-sizing: border-box;
            }
        }
    }

    .integrationsContainer {
        display: flex;
        flex-direction: column;
        width: fit-content;
        gap: 12px;

        .togglers {
            display: flex;
            flex-direction: column;
            // gap: 1rem;

            .label {
                font-size: 14px;
                display: flex;
                gap: 6px;
                align-items: center;
            }
        }
    }

    .infoContainer {
        flex-direction: row;
        display: flex;
        font-family: inherit;
        font-size: 1.4rem;
    }

    .infoContent {
        margin-left: 16px;
        font-size: 14px;
    }

    .iconButton {
        width: 4rem;
        height: 4rem;
        margin-left: 1rem;
    }

    .icon {
        cursor: pointer;
        height: 24px;
    }

    .progress {
        position: absolute;
    }

    .errorMessage {
        margin-top: 1rem;
        color: var(--selected-container-color);
    }

    .button {
        font-size: 14px;
        height: auto;
    }

    .submitButton {
        margin-top: auto;
        margin-left: auto;
    }
}
