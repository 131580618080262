@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 169px;
    box-sizing: border-box;
    background: #282A3F;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 0px 50px rgba(21, 23, 44, 0.4);
    border-radius: 6px;

    .title {
        padding: 12px 20px;
        border: 1px solid #FFFFFF;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        font-family: 'Open Sans';
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;

        img {
            cursor: pointer;
            filter: brightness(0) invert(1);
        }
    }

    .content {
        padding: 20px 16px;

        .label {
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 40px;
        }

        .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .cancel {
                width: 54px;
                height: 19px;

                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                /* identical to box height */

                text-transform: uppercase;

                /* Text/Primary */
                margin-right: 15px;
                color: #FFFFFF;
                cursor: pointer;
            }

            .confirmButton {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 4px 16px;
                gap: 6px;
                width: 56px;
                height: 28px;
                background: #E12468;
                border-radius: 2px;
                font-weight: 600;
                font-family: 'Open Sans';
                text-transform: uppercase;
                line-height: 19px;
                font-size: 14px;
                cursor: pointer;
                
                &:hover {
                    background: #BF1F58;
                }
            }

        }
    }
}

.modal {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(56, 56, 82, 0.6);
}