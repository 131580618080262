@import 'mixins';
@import 'variables';
@import 'colors';

/* Global Overrides */
@import './overrides/highcharts-overrides.scss';

iframe {
    // pointer-events: none;
}

*::-webkit-scrollbar {
    display: none;
}

*::-webkit-scrollbar:hover {
    opacity: 0.8;
}

*::-webkit-scrollbar-track {
    box-shadow: 'inset 0 0 6px rgba(0,0,0,0.00)';
    -webkit-box-shadow: 'inset 0 0 6px rgba(0,0,0,0.00)';
}

*::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to right, #fe0072, #fe0072);
    outline: 1px solid slategrey;
    background: '#fe0072' !important;
}

html {
    font-size: var(--font-size);
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background-color: #14172c;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans';
    font-size: 1.4rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    text-align: left;
    color: #ffffff;
    height: 100%;
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
}

div {
    box-sizing: border-box;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #383852 inset !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.no-data {
    padding: 0px 15px 0px 15px;
    padding-top: 30px;
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
}

h2 {
    font-size: 3rem;
    line-height: 3rem;
}

h4 {
    font-size: 1.5rem;
    line-height: 2.5rem;
}

/* Layout */
.flex-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

#root {
    min-height: 100%;
}

/* svg.MuiSvgIcon-root {
    font-size: 2.5rem;
} */
