.scaleBar {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 120px;
}

.bar {
    background: linear-gradient(90deg, #ffd600, #feab45, #ff4e4e);
    border-radius: 4px;
    height: 9px;
}
