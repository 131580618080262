@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.container {
  position: relative;
  height: 30px;
  width: 360px;
  .selectInput {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #fff;
    border-radius: 2px;
    height: 30px;
    cursor: pointer;
    padding: 6px;
    &.active {
      border: 1px solid #EA3385;
    }
    .placeholder {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.2);
    }
    .tick {
      margin-left: auto;
      padding-right: 8px;
      cursor: pointer;
    }
    .label {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      .listOfSelected {
        align-items: center;
        margin-left: 3px;
        white-space: nowrap;
        max-width: 260px;
        display: flex;
        .selectedOneItem {
          font-size: 14px;
          margin-right: 4px;
          font-weight: 400;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
  .dropdown {
    max-height: 300px;
    overflow: auto;
    position: absolute;
    z-index: 1;
    background-color: #282A3F;
    top: calc(100% - -4px);
    left: 0;
    width: 100%;
    border: solid 1px #696a79;
    border-radius: 2px;
    .items {
      padding: 2px 0;
      display: flex;
      flex-flow: column;
      .item {
        font-size: 14px;
        height: 30px;
        width: 100%;
        padding: 2px 8px;
        display: flex;
        align-items: center;
        &:hover {
          background-color: #696a79;
          cursor: pointer;
        }
      }
      .searchBox {
        display: flex;
        width: 100%;
        height: 30px;
        margin-top: 8px;
        margin-bottom: 8px;
        border: 0.5px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 2px;
        background-color: transparent;
        padding: 5px 0 5px 12px;
        align-items: center;
        img {
          height: 14px;
          width: 14px;
        }
        .input {
          margin-left: 5px;
          outline: none;
          border: none;
          width: 100%;
          background-color: transparent;
          color: #FFFFFF;
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.3);
          }
        }
      }
    }
  }
}