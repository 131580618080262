@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.container {
    margin-top: 10px;
    display: flex;
    flex-flow: column;
    .checkbox {
        display: flex;
        flex-flow: column;
        margin-top: 10px;
        .label {
            color: #ffffff;
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
}

.error {
    color: red;
    font-size: 14px;
    line-height: 22px;
}
.container {
    margin-bottom: 10px;
    .items {
        display: flex;
        gap: 1.5rem;
        align-items: center;
        .deleteIcon {
            color: #ffffff;
            cursor: pointer;
            margin-top: 24px;
        }
        .item {
            font-size: 14px;
            flex: 1 1 0px;
            .data {
                margin-top: 5px;
                align-items: center;
                justify-content: space-between;
                display: flex;
                background: transparent;
                padding: 4px 4px 4px 12px;
                box-sizing: border-box;
                border: 1px solid #ffffff;
                border-radius: 2px;
                .channel {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .icon {
                    cursor: pointer;
                    svg {
                        height: 16px;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.listContainer {
    display: flex;
    flex-direction: column;
    gap: 0.85rem;
}
