.component {
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
}

.legend-row {
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.legend-item {
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-left: 2.4rem;
}

.legend-item:first-of-type {
    margin-left: 0;
}

.legend-item b {
    font-weight: 600;
    margin-left: 0.5rem;
}
