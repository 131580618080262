@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.container {
    height: 100%;
    overflow: auto;
    width: 100%;
    background-color: #282a40;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 100%;
}

.container iframe {
  width: 100%;
  height: 100%;
}

.root {
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
}

.dataContainer {
  padding: 24px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dashboard {
    height: 100%;
}

