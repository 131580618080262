@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 1.9rem;
    line-height: 1.9rem;
}
