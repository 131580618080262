@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.header {
    background: #383852;
    box-shadow: 0px 6px 6px #15172c;
    min-height: 64px !important;
    justify-content: space-between;
    padding: 0 2.4rem;
    color: #fff;
    z-index: 2;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
}

.buttonWrapper {
    margin-right: 1rem !important;
    height: 2rem !important;
    max-width: 2.5rem !important;
    border-radius: 3px !important;
    font-weight: 600 !important;
    font-size: 1.2 !important;
}

.rightSideWrapper {
    display: flex;
}

.divider {
    display: flex;
    color: white;
    height: 46px;
    width: 1px;
    background-color: white;
}

.title {
    font-size: 30px;
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    gap: 12px;
    align-items: center;
}

.rightContainer {
    display: flex;
    width: 122px;
    justify-content: flex-end;
    height: 24px;
    line-height: 24px;
}

.headerContent {
    flex: 1;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: flex-end;
}

.profileName {
    margin-left: 8px;
    cursor: pointer;
    margin-top: 4px;
}

.profileArrow {
    margin-left: 5px;
    cursor: pointer;
}

.container {
    background-color: #383852;
    height: 60px;
    display: flex;
    box-shadow: 0 4px 30px 0 #15172c;
    align-items: center;
    z-index: 2;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    color: #ffffff;
    padding: 18px 20px;
    box-sizing: border-box;
    justify-content: space-between;
    font-family: Open Sans;
    .label {
        font-size: 18px;
    }
    .userContainer {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        img {
            margin-right: 8px;
        }
        .dropdown {
            margin-left: 5px;
        }
    }
    .actionsContainer {
        background: #282a3f;
        border: 0.5px solid #ffffff;
        box-shadow: 0 5px 30px rgba(21, 23, 44, 0.5);
        border-radius: 2px;
        padding: 9px 13px;
        box-sizing: border-box;
        position: absolute;
        width: 200px;
        z-index: 1;
        right: 20px;
        top: 55px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        cursor: pointer;
        color: #ffffff;
        img {
            margin-right: 8px;
        }
        .user-img {
            filter: invert(58%) sepia(74%) saturate(6889%) hue-rotate(312deg) brightness(92%) contrast(99%);
            width: 20px;
        }
        .actionContainer {
            display: flex;
        }
    }
}
