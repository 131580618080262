@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 290px);
  margin-top:20px;
  background: #383852;
  border-radius: 6px;
  .label {
    font-family: Open Sans;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
  }
}