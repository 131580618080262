@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
    width: 100%;
    padding: 16px;
    background-color: #383852;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .flex {
        display: flex;
        align-items: center;
        .filters {
            font-family: inherit;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
        }

        .selectors {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .item {
                display: flex;
                width: 200px;
                align-items: center;
                font-size: 14px;
            }
        }
    }

    .button {
        margin-left: 21px;
        border-radius: 2px;
        font-size: 14px;
        margin-top: 2px;
        font-family: 'Open Sans';
        color: #ea3385;
        text-transform: uppercase;
        cursor: pointer;
    }
}
