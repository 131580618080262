@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/colors';

.tooltipHeader {
    padding: 12px;
    display: flex;
    gap: 8px;
    align-items: center;
}

.skeletonLoader {
    min-height: 320px;
}

.tooltipDivider {
    height: 0.5px;
    width: 100%;
    background: #e6e6e6;
}

.tooltipBody {
    padding: 12px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.tooltipBody div {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
}
