@import '../../../../styles/mixins';
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.container {
    font-size: 16px;
    padding: 24px;
    width: 100%;
    background: #14172c;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .mainLoading {
        display: flex;
        justify-content: center;
        height: 600px;
    }

    .errorContainer {
        color: #ff4e4e;
        align-self: center;
    }

    .inputsContainer {
        gap: 24px;
    }

    .inputs {
        display: flex;
        align-items: center;
        width: 242px;
        border-radius: 4px;
        height: 40px;
        .item {
            display: flex;
            width: 200px;
            align-items: center;
            font-size: 14px;
        }
    }

    .titleContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            display: flex;
            font-size: 18px;
            font-family: inherit;
            font-weight: 600;

            .connect {
                padding-left: 15px;
                height: 19px;
                align-items: center;
                margin-top: 4px;
                display: flex;
                gap: 5px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #2ed15e;
            }
        }
    }

    .innerContainer {
        background: #282b3f;
        padding: 10px 0 0rem 0;
        border-radius: 5px;
        position: relative;
    }

    .divider {
        margin: 25px 10px 15px 10px;
    }

    .marketoXSignatureGap {
        gap: 3rem !important;
    }

    .connectorContainer {
        display: flex;
        flex-direction: column;
        padding-top: 1.5rem;
        gap: 0.5rem;

        .disabledSaveDisconnectButton {
            background: var(--surface-alpha-3, rgba(255, 255, 255, 0.1)) !important;
            cursor: unset !important;
            width: 135px !important;
            display: flex;
            gap: 7px;

            &:hover {
                background: var(--surface-alpha-3, rgba(255, 255, 255, 0.1)) !important;
            }
        }

        .disconnectLoaderButtonText {
            color: var(--text-disable, #788397) !important;
        }

        .disconnectTextLoading {
            margin: 20px 0 0px 0px;
            font-size: 14px;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .enrichedFieldsTitle {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 5px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            margin-left: 20px;
        }

        .disabledButton {
            cursor: unset !important;
            background-color: rgba(255, 255, 255, 0.1) !important;

            .connectionText {
                color: #788397 !important;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.1) !important;
            }
        }

        .connectorButton {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 28px;
            background: #e12468;
            border-radius: 2px;
            cursor: pointer;
            margin: 25px 10px 0 20px;

            .connectionText {
                width: 89px;
                height: 19px;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                text-transform: uppercase;
                color: #ffffff;
                flex: none;
                order: 1;
                flex-grow: 0;
            }

            &:hover {
                background: #bf1f58;
            }
        }

        .custom-success-toast {
            background-color: green;
        }

        .disconnectButton {
            width: 121px;
        }

        .connectButton {
            width: 99px;
            margin: 25px 20px 10px 17px;
        }
    }

    .saveFormsContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 10px 0 0 0;
    }

    .formsContainer {
        display: flex;
        flex-direction: column;
        padding: 0 17px 50px 17px;

        .tableContainer {
            height: 100px;
            overflow: scroll;
        }

        .actionContainer {
            display: flex;
            height: 28px;

            .inputContainer {
                background-color: transparent;
                border-radius: 2px;
                display: flex;
                border: #ffffff 1px solid;
                padding: 2px 5px 2px 10px;

                .searchInput {
                    border: none;
                    color: #ffffff;
                    background-color: transparent;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    width: 150px;
                    font-size: 14px;
                    line-height: 19px;
                    margin-left: 4px;

                    &:hover {
                        border: none;
                    }

                    &:focus {
                        outline: none;
                    }

                    &::placeholder {
                        opacity: 0.5;
                        color: #788397;
                        font-size: 1px;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 19px;
                    }
                }

                .searchIcon {
                    color: #ffffff;
                    font-size: 14px;
                }
            }
        }

        .formsHeaderContainer {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;

            .formsTitleContainer {
                display: flex;
                flex-direction: column;

                .subTitleContainer {
                    display: flex;
                    gap: 10px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    align-items: center;
                    font-size: 14px;
                    line-height: 19px;

                    .protectionNewFormContainer {
                        display: flex;
                        gap: 5px;
                        align-items: center;

                        .protectionDivider {
                            height: 20px;
                            background-color: #788397;
                        }

                        .protectCheckbox {
                            padding: 0 0 0 3px;
                        }
                    }
                }

                .mainTitle {
                    height: 22px;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #ffffff;
                }
            }
        }
    }

    .saveFormsButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 66px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        margin-left: 10px;
        background: #e12468;
        border-radius: 2px;
        cursor: pointer;
        height: 28px;
        gap: 8px;
        align-self: end;

        &:hover {
            background: #bf1f58;
        }
    }

    .disabledSaveFormsButton {
        background: transparent;
        color: #788397;
        border: #788397 1px solid;
        cursor: unset;

        &:hover {
            background: transparent;
        }
    }

    .saveFormsLoadingText {
        font-size: 14px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .saveFormsContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 10px 0 0 0;
    }

    .fetchFormsErrorContainer {
        height: 210px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;

        .errorIcon {
            filter: brightness(0) saturate(100%) invert(53%) sepia(12%) saturate(540%) hue-rotate(180deg) brightness(93%) contrast(87%);
        }
    }

    .paginationContainer {
        display: flex;
        align-items: flex-start;
        padding: 12px 20px;
        gap: 10px;
        right: 1px;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        height: 48px;
        background: #282a3f;
        box-shadow: 0px 4px 30px #15172c;
        border-radius: 0px 0px 4px 4px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;

        .resultText {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #ffffff;
        }

        .pageControlContainer {
            display: flex;
            gap: 5px;

            .separatePageInfo {
                margin: 0 5px 0 5px;
            }

            .prevIcon {
                transform: rotate(90deg);
                margin-right: 5px;
                margin-top: 2px;
            }

            .pageNavigationButton {
                cursor: pointer;
                display: flex;
            }

            .nextIcon {
                transform: rotate(270deg);
                margin-left: 5px;
                margin-top: 2px;
            }

            .pageDiv {
                box-sizing: border-box;
                text-align: center;
                padding: 1px 4px;
                display: flex;
                width: 50px;
                color: black;
                height: 20px;
                background: #ffffff;
                border: 1px solid #788397;
                border-radius: 2px;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .laptopWidth {
        width: 70rem !important;
    }

    .desktopWidth {
        width: 50rem !important;
    }

    .formsSubtitles {
        gap: 1rem;
        display: flex;
        flex-direction: column;
    }
}
