@import '../../../../styles/mixins';
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.title {
    font-size: 1.6rem;
    color: #ffffff;
}
.passwordContainer {
    width: 100%;
    position: relative;
}

.passwordRules {
    position: absolute;
    top: calc(100%);
    left: 5px;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: none;
    flex-direction: column;
    padding: 16px;
    border-radius: 4px;
    box-sizing: border-box;
    gap: 8px;
    color: black;
    font-size: 14px;
    width: 450px;
    z-index: 99999;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.5);
}

.show {
    display: flex !important;
}

.rule {
    display: flex;
    gap: 8px;
}

.checkboxTzLangPrivacyText {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.checkboxTzLang {
    display: flex;
    width: 30%;
    flex-wrap: wrap;
}
