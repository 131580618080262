@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.root {
    height: 100%;
    display: flex;
}

.left {
    width: 310px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right {
    margin-top: 0.5rem;
    overflow-x: hidden;
    padding: 0 2rem 8rem 2rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;

    .tableWrapper {
        background: rgba(20, 23, 44, 0.5);
        flex: 1;
    }

    .header {
        height: 60px;
        padding: 1rem 0;

        .timzone {
            text-align: left;
            font-size: 1.4rem;
        }

        .date {
            text-align: left;
            font-size: 1.4rem;
        }
    }
}
