@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
    display: grid !important;
    grid-template-columns: repeat(21, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 16px;
    width: 100%;

    .campaigns {
        grid-row: 1;
        grid-column: 1/14;
        margin: 0;
    }

    .landingPages {
        grid-row: 1;
        grid-column: 14/22;
        margin: 0;
    }

    .keywords {
        grid-column: 1/7;
        grid-row: 2;
        margin: 0;
    }

    .countries {
        grid-column: 7/14;
        grid-row: 2;
        margin: 0;
    }

    .heatMap {
        grid-column: 14/22;
        grid-row: 2;
        margin: 0;
    }
}
