.container {
    background-color: white !important;
    color: var(--container-color) !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    margin: 0 !important;
}

.title-container {
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
}

.title {
    padding: 0;
    font-weight: 600;
}

.bullet {
    padding-right: 0.8rem;
}

.contnet-container {
    padding: 0 2rem 1.2rem 1.6rem !important;
}

p {
    line-height: 1.9rem;
    margin: 0;
}
