@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.root {
    margin: 0 !important;
    width: 100%;
    min-height: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    align-self: stretch;
}
.wrapper {
    min-height: 100% !important;
    align-self: stretch;
}
.input {
    text-align: center !important;
    padding: 0 6px !important;
    min-height: 100%;
}

.datePickerInput {
    cursor: pointer;
    width: fit-content;
    min-height: 100%;
    align-self: stretch;

    @include smallFontSize;
}

.fullWidth {
    width: 100% !important;
    flex: 1;
}
