@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.toggle {
    width: 22px;
    height: 14px;
    border-radius: 20px;
    transition: background-color 0.5s;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
}

.toggle:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    background: var(--container-color);
    width: 6px;
    height: 6px;
    border: 1px solid #ffffff;
    border-radius: 100%;
    transition: left 0.5s;
    box-sizing: border-box;
}

.toggle.checked {
    background-color: var(--selected-container-color);
}

.toggle.checked:after {
    box-sizing: content-box;
    border: 1px solid var(--selected-container-color);
    left: calc(100% - 10px);
}

.toggle:not(.checked) {
    background-color: transparent;
    border: 1px solid #ffffff;
}

.switch {
    margin: 0 !important;
    display: inline-flex;
    gap: 1rem;
    margin: 0;

    .root {
        width: 28px;
        height: 16px;
        padding: 0;
        display: flex;
        align-items: center;
        position: relative;
    }

    .label {
        font-size: 1.3rem;
        @include flexRow();
        gap: 1rem;
    }

    .switchBase {
        padding: 2px;
        color: white;
    }

    .checked {
        transform: translateX(12px) !important;
        color: white !important;

        & + track {
            opacity: 1;
        }
    }

    .thumb {
        width: 12px;
        height: 12px;
        box-shadow: none;
    }

    track {
        border-radius: 16 / 2;
        opacity: 1;
        background-color: white;
    }
}
