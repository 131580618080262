@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.root {
    @include rounded;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background-color: $paperColor;
    display: flex;
    align-items: center;
    gap: 12px;

    .customDateRange {
        min-height: 100%;
    }

    .refreshButton {
        margin-left: auto;
    }

    .lastUpdated {
        display: flex;
        gap: 6px;
        align-items: center;
    }
}
