.container {
    width: 100%;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    display: flex;
}

.bar {
    font-size: 11px;
    height: 30px;
    border-radius: 3px;
    border-right: 0.5px solid #383852;
    display: flex;
    min-width: fit-content;
    padding: 0 4px;
    box-sizing: border-box;
}

.bar .text {
    gap: 3px;
    display: flex;
    align-items: center;
}

.legend {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 0.5px solid rgb(230, 230, 230);
    margin-right: 10px;
    display: inline-flex;
}

.legendText {
    display: inline-flex;
    min-width: 30%;
}

.legendLine {
    margin: 0.6rem 0;
}

b {
    margin-left: 0.5rem;
}