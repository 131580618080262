@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
    display: flex;
    flex-direction: column;
    min-width: 34rem;
    min-height: 28rem;
    background-color: #282b3f;
    padding: 2.5rem;
    border-radius: 2px;

    .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 2rem;

        .title {
            font-size: 2rem;
        }

        .closeIcon {
            color: #fe0072;
            cursor: pointer;
        }
    }

    .inputContainer {
        margin: 0;
        border: none;
    }

    .inputItem {
        margin-bottom: 2rem;
        .input {
            background-color: #0c1021;
            color: #fff;
            border: 'none';
            padding-left: 1rem;
            border-radius: 2px;
            width: 100%;
            font-size: 1.6rem;
            font-family: inherit;
            margin-bottom: 5px;
            width: 100% !important;
            &.error {
                border-color: red;
            }
        }

        .error {
            font-size: 14px;
            color: red;
            margin-left: 4px;
        }
    }

    .descriptionInput {
        font-family: inherit;
        width: 100%;
        height: 10rem;
        resize: none;
        border: none;
        font-size: 1.6rem;
        font-family: inherit;
        background-color: #0c1021;
        color: #fff;
        padding: 1rem;
        margin-bottom: 2rem;
        border-radius: 2px;
        border-color: Transparent;
        outline: none;

        &:focus {
            border: 1px solid #fe0072;
        }

        &::placeholder {
            font-family: inherit;
            font-size: 1.6rem;
            filter: brightness(40%);
        }
    }

    .buttonsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
    }

    .button {
        font-size: 1.6rem;
        min-width: 15rem;
    }
}
