@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.menuItems {
    width: 342px;
    display: flex;
    border: 1px solid #ffffff;
    height: 30px;
    border-radius: 2px;
    font-size: 1.3rem;
    text-transform: uppercase;
    cursor: pointer;
}

.menuItems > div {
    transition: background-color 0.3s, color 0.3s;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    background-color: transparent;
}

.menuItems > div.current {
    color: var(--selected-container-color);
    background-color: white;
}

.menuItems div:nth-child(2) {
    border-right: 1px solid white;
    border-left: 1px solid white;
}

.titleWrapper {
    display: flex;
    align-items: center;
}

.titleWrapper > div:nth-child(1) {
    margin-right: 24px;
}

.paddedContainer {
    padding: 1.6rem;
}

.topThreatSkeletonLoader {
    min-height: 30rem !important;
}

.topThreatsContent {
    height: 30rem;
    max-height: 400px;
}

.barGraph {
    flex: 1;
    height: 100%;
}

.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
    min-width: 24px;
}

.visits {
    display: flex;
    justify-content: center;
    align-items: center;
}

.threatGroups {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: auto;
    /* min-height: 240px; */

    .threatGroupTitle {
        white-space: nowrap;
    }

    .topThreatTitle {
        white-space: nowrap;
    }
}

.threatGroups table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.threatGroups th {
    font-size: 1.2rem;
    padding-bottom: 5px;
    border-bottom: 0.5px solid var(--border-seperator-color-light);
}

.threatGroups > table > tr > td:nth-child(1) div {
    position: relative;
    height: 100%;
}

.threatGroups > table > tr > td:nth-child(1) div:after {
    content: '';
    background-color: var(--color);
    border: 0.5px solid var(--border-seperator-color-light-darker);
    box-sizing: border-box;
    border-radius: 2px;
    display: inline-block;
    margin-right: 11px;
    height: 14px;
    width: 14px;
    position: absolute;
    left: 0;
    top: 0;
}

.threatGroups > table > tr > td:nth-child(1) div:before {
    width: 23px;
    display: inline-block;
    content: '';
}

.threatGroups td {
    position: relative;
    padding: 8px;
    font-size: 1.4rem;
    border-bottom: 0.5px solid var(--border-seperator-color);
    box-sizing: border-box;
}

.threatGroups tr:last-child td {
    border-bottom: 0;
}

.threatGroups ul {
    padding: 0;
    list-style: none;
    margin: 0;
}

.threatGroups li {
    display: flex;
    font-size: 1.3rem;
    border-radius: 4px;
    gap: 6px;
}

.threatGroups li.unprotected {
    background-color: rgba(230, 230, 230, 0.1);
}

.threatGroups li:before {
    content: '•';
    margin-right: 4px;
}

.threatGroups li > div:nth-child(1) {
    flex: 1;
}

.threatGroups li > div:nth-child(2) {
    font-weight: 600;
}

.threatGroupsTitleContainer {
    display: flex;
    align-items: center;
}

.threatGroupsTitle {
    margin-right: 0.6rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.potentialReplacedVisitsContainer {
    height: 17rem;
}

.potentialReplacedVisitsContainer .title {
    padding: 0.5rem 0 0.5rem 0;
}

.potentialReplacedVisitsContainer .content {
    padding: 2.4rem 1.6rem 1.6rem 1.6rem;
}

.potentialReplacedVisitsContainer .potentialBlockedValue {
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 3rem;
    font-family: inherit;
}

.potentialReplacedVisitsContainer .replacedVisitsRow {
    margin-bottom: 1.5rem;
}

.potentialReplacedVisitsContainer .value {
    font-weight: 600;
    line-height: 3rem;
}

.calculatedReplacedVisitsContainer .title {
    padding: 0.5rem 0 0.5rem 0;
}

.calculatedReplacedVisitsContainer .content {
    padding: 2.4rem 1.6rem 1.6rem 1.6rem;
}

.calculatedReplacedVisitsContainer .blockedUsersValue {
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 3rem;
    font-family: inherit;
}

.calculatedReplacedVisitsContainer .replacedVisitsRow {
    margin-bottom: 1.5rem;
}

.calculatedReplacedVisitsContainer .value {
    font-weight: 600;
    line-height: 3rem;
}

.calculatedReplacedVisitsContainer .returnRate {
    width: 14rem;
    display: inline-block;
}

.calculatedReplacedVisitsContainer .separator {
    border-color: rgba(255, 255, 255, 0.4);
    background-color: rgba(255, 255, 255, 0.4);
}

.blockedEntitiesContainer .title {
    padding: 0.5rem 0 0.5rem 0;
}

.blockedEntitiesContainer .content {
    padding: 2.4rem 1.6rem 1.6rem 1.6rem;
}

.blockedEntitiesContainer .blockedEntitiesValue {
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 3rem;
    font-family: inherit;
}

.blockedEntitiesContainer .replacedVisitsRow {
    margin-bottom: 1.5rem;
}

.replacedVisitsContainer .horizontalSeparator {
    height: 0;
    width: 100%;
    border-top: 0.5px solid rgba(255, 255, 255, 0.5);
    margin: 1.2rem 0;
}

.invalidVisitsInsightsContainer {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    gap: 2rem;
    width: 100%;
}

.topThreatTypeContainer {
    margin: 0;
}

.replacedVisitsWrapper {
    display: flex;
    gap: 16px;
}

.potentialReplacedVisitsContainer {
    margin: 0;
    width: 100%;
}

.calculatedReplacedVisitsContainer {
    max-width: 50%;
}

.blockedEntitiesContainer {
    max-width: 50%;
}
