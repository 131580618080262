@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/colors';

.keywords {
    grid-column: 1/7;
    grid-row: 2;
    margin: 0;
}

.skeletonLoader {
    min-height: 320px;
}

.term {
    max-width: 8rem;
    /* flex: 1; */
}

.termText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.invalidVisits {
    display: flex;
    gap: 1rem;
    flex: 1;
}

.scaleBar {
    min-width: 10rem;
    width: 100%;
}

.allVisits {
    flex: 1;
}
