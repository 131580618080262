@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.icon {
    cursor: pointer;
}

.root {
    width: 350px;
    border-left: 10px solid #2ed15e;
    border-radius: 8px !important;
    background-color: #d5f6df !important;
}

.container {
    display: flex;
    .textContainer {
        display: flex;
        flex-flow: column;
        margin-left: 14px;
        .label {
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 19px;
            color: #2ed15e;
            &:last-child {
                margin-top: 8px;
            }
        }
    }
}
