@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.input {
    width: 20rem;
    height: 2.8rem;
    border-radius: 2px;
    border: 1px #ffffff solid;
    color: #ffffff;
    gap: 118px;
    background-color: transparent;
}
.input.disabled {
    border: 1px #788397 solid;
    color: #788397;
}
.input::placeholder {
    font-size: 1.3rem; /* You can adjust the size to your preference */
    color: #788397;
}
.showSecret {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
}
