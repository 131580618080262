.item-container {
    /* padding-top: 9px; */
}

.item-container div{
    display: inline-block;
    vertical-align: sub;
}

.score-container{
    display: inline !important;
    position: relative;
    left:-30px;
}

.item-container .label {
    position: relative;
    left: 10px;
    font-size: 14px;
    /* width:75%; */
}

.item-container .value {
    position: relative;
    float:right; 
    clear:right;
    margin-top: 4px;
}