.highcharts-container {
    font-family: 'Open Sans';
    font-size: 1.2rem;
}

.highcharts-background {
    fill: none;
}

.vertical-bar-graph .highcharts-grid-line {
    fill: none;
    stroke: #ffffff;
    stroke-width: 0.5px;
    stroke-opacity: 20%;
}

.vertical-bar-graph .highcharts-xaxis-grid .highcharts-grid-line {
    fill: none;
    stroke: #ffffff;
    stroke-width: 0.5px;
    stroke-opacity: 20%;
}

.vertical-bar-graph .highcharts-axis-line {
    display: none;
}

.vertical-bar-graph .highcharts-axis-labels text {
    color: #ffffff !important;
    fill: #ffffff !important;
    font-size: 1rem !important;
    line-height: 1.2rem !important;
}
