@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #fc5296;
  background-image: linear-gradient(315deg, #fc5296 0%, #f67062 74%)
}