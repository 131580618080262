.box {
    width: 102px;
    height: 102px;
    background: rgba(56, 56, 82, 0.5);
    border-radius: 4px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
}

.content_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.content_wrapper div:nth-child(1) {
    margin-bottom: 5px;
}

.selected {
    background: linear-gradient(133.45deg, #6936fb -49.99%, #fe0072 95.22%);
}

.tab-panel {
    min-height: 100vh;
}
