@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.icon {
  cursor: pointer;
}

.root {
  width: 350px;
  border-left: 10px solid #2ED15E;
  border-radius: 8px !important;
  background-color: #D5F6DF !important;
}
.failure {
  width: 370px;
  border-left: 10px solid #FF4E4E;
  border-radius: 8px !important;
  background-color: #FFE4E4 !important;
}

.container {
  display: flex;
  .textContainer {
    display: flex;
    flex-flow: column;
    margin-left: 14px;
    .label {
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 19px;
      color: #FF4E4E;
      &.success {
        color: #2ED15E;
      }
      &:last-child {
        margin-top: 8px;
      }
    }
  }
}