@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;

    .sectionTitle {
        font-size: clamp(18px, 1.5rem, 48px);
        font-weight: 600;
    }
    & > h4 {
        margin: 0 !important;
    }
}

.content {
    display: flex;
    gap: 16px;
    margin: 0 !important;
}
