@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: $dashboardSeperator;
    .row {
        display: flex;
        gap: 16px;
        width: 100%;

        // Make the pie chart take up more space
        & > div:first-child {
            width: 140%;
            @media only screen and (max-width: $screenLG) {
                width: 150%;
            }
        }
    }

    .items {
        display: flex;
        width: 100%;
        .row {
            width: 100%;
            display: flex;
            gap: 16px;
            margin-right: 20px;
            margin-bottom: 12px;
        }
        .trends {
            width: 60%;
            height: 100%;
        }
    }
    .totalValues {
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;

            .values {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-content: center;
                gap: 6px;

                .value {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    .label {
                        grid-row: 1;
                        grid-column: 1;
                        display: flex;
                        align-items: flex-start;
                    }
                    .flex {
                        grid-row: 2;
                        grid-column: 2;
                        display: flex;
                        gap: 6px;
                    }
                }
            }
        }
    }

    .item {
        .wrapper {
            display: grid;
            grid-template-columns: repeat(2, minmax(28px, auto));
            grid-template-rows: repeat(2, auto);
            justify-content: flex-start;
            align-content: center;
            height: 100%;
            padding: 12px;

            .color {
                grid-row: 1;
                grid-column: 1;
                display: flex;
                align-items: center;
            }

            .numbers {
                grid-row: 1;
                grid-column: 2;
                display: inline-block;

                .value {
                    font-size: 2.5rem;
                    font-weight: 600;
                }

                .percentage {
                    font-size: 16px;
                    font-weight: 400;
                    margin-left: 6px;
                }
            }

            .outOfTotal {
                grid-row: 2;
                grid-column: 2;
                display: flex;
                gap: 6px;
            }
        }
    }
}
