@import '../../../../styles/mixins';
@import '../../../../styles/variables';
@import '../../../../styles/colors';

.container {
  width: 100%;
  .inputContainer {
    margin-bottom: 10px;
    .label {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 8px;
    }
    .labelToolTip {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      grid-gap: 12px;
      gap: 12px;
    }
    .error {
      margin-top: 4px;
      font-size: 14px;
      color: #ff4e4e;
    }
    .flexItem {
      display: flex;
      align-items: center;
      gap: 12px;
      .text {
        font-family: Open Sans;
        font-size: 14px;
        line-height: 19px;
        margin-right: 10px;
      }
    }
  }
  .right {
    justify-content: center;
    display: flex;
    .button {
      height: 30px;
      width: 139px;
      color: #FFFFFF;
      font-size: 14px;
      border-radius: 2px;
    }
  }
  .sendToContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .label {
      font-family: Open Sans;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      margin-right: 18px;
    }
  }
}

@media only screen and (max-width: $screenLG) {
  .container {
    .inputContainer {
      .flexItem {
        &.accessSecret {
          display: unset;
        }
      }
    }
    .right {
      float: right;
      margin-right: 48px;
      margin-top: 24px;
    }
  }
}
