@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.container {
    background-color: #14172c;
    color: white;
    border-radius: 0.6rem !important;
    box-shadow: 0px 0px 50px rgba(21, 23, 44, 0.4) !important;
    font-size: 1.4rem !important;
    border-top: 0.8rem solid var(--selected-container-color) !important;
    min-width: 45rem !important;
    max-height: 60rem !important;
    overflow: hidden !important;
}

.title {
    border-bottom: 0.1rem solid var(--border-seperator-color-light-darker);
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 2rem;
    font-weight: 600;
    font-size: 1.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.close-icon {
    width: fit-content !important;
    position: absolute !important;
    right: 0.3rem !important;
}

.content-container {
    overflow-y: auto;
}

.content {
    margin: 1.6rem 2rem 1.6rem 2rem;
}

.buttonContainer {
    margin: 1rem;
}

.button {
    width: '-webkit-fill-available';
    padding: 0 1.6rem 0 1.6rem;
    font-size: 1.2rem !important;
    font-family: inherit !important;
    font-weight: 600 !important;

    &:hover {
        filter: brightness(90%);
    }
}

.okButton {
    color: #ffffff !important;
    background-color: var(--selected-container-color) !important;
}