@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.root {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;

    .top {
        display: flex;
        min-height: 100%;
        justify-content: center;
        align-items: center;
    }
}

.dataContainer {
    padding: 24px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.statusMessage {
    @include flexRow(center);
    height: 1.9rem;
    line-height: 1.9rem;
}

.header {
    top: 0;
    display: flex;
    justify-content: center;
    position: sticky;
    background: #383852;
    box-shadow: 0px 12px 12px #15172c;
    z-index: 1;
}
