.indicator {
    background: #ea3385 !important;
}

.flex-container {
    height: 100% !important;
    flex: 1;
}

.root {
    background: transparent !important;
    min-height: 100% !important;
    align-self: flex-end;
}

.tab {
    background: transparent !important;
    color: white !important;
    font-size: 1.8rem;
    font-weight: 600;
    align-items: flex-end !important;
    max-width: initial !important;
}

.wrapper {
    display: flex;
    flex-direction: row !important;
    font-weight: 600;
    justify-content: center !important;
    font-size: 1.8rem;
    gap: 1rem;
}

.label-icon {
    min-height: 48px !important;
}

.label-icon svg {
    margin-bottom: 0 !important;
}

.iconContainer {
    position: relative;
    margin-right: 1rem;
    width: 2.5rem;
    height: 100%;
    display: flex;
    align-items: center;
}
