.tool-tip {
    background-color: #ffffff !important;
    color: var(--container-color) !important;
    padding: 1rem !important;
    border-radius: 0.6rem !important;
    box-shadow: 0px 0px 50px rgba(21, 23, 44, 0.4) !important;
    font-size: 14px !important;
    z-index: 100;
    min-width: fit-content;
}

.text {
    color: var(--container-color);
    margin-bottom: 0.6rem;
    min-width: fit-content;
}

.title {
    font-weight: bold;
    white-space: nowrap;
}

.button-container {
    cursor: pointer;
    margin-top: 10px;
    height: 20px;
}

.button-container:hover {
    opacity: 0.8;
}

.button {
    color: var(--selected-container-color);
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0;
    border: none;
    background-color: white;
    margin-right: 6px;
}

.arrow {
    padding-left: 0.6rem;
    height: 1.2rem;
}

.tool-tip-icon {
    cursor: pointer;
    padding: 0.6rem;
}

.tool-tip-icon:hover {
    opacity: 0.8;
}
