@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.root {
    width: 465px;
    user-select: none;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    display: inline-flex;
    position: relative;
    flex: 1;
    overflow: auto;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    padding: 0 12px;
    box-sizing: border-box;
    line-height: 19px;

    &:focus-within {
        border: 1px solid #EA3385;
    }
    .chips {
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
        height: fit-content;
        width: fit-content;
        overflow: auto;
    }
    .input {
        height: 100%;
        flex: 1;
        min-width: fit-content;
        display: flex;
        align-items: center;
        .inputRoot {
            min-width: fit-content;
            display: flex;
            width: 100%;
        }
        input {
            height: 100%;
            &::placeholder {
                color: rgba(255, 255, 255, 0.3);
                font-size: 14px;
            }
        }
    }
}
