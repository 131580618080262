@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.brandsWrapper{
  height: 100%;
   width: 100%; 
   display: flex;
  .container {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    background-color: #282A3F;
    .icon {
      width: 273px;
      height: 81px;
      margin-bottom: 40px;
    }
    .label {
      font-size: 24px;
      font-weight: 600;
    }
    .selectContainer {
      margin-top: 20px;
    }
    .button {
      margin-top: 20px;
      color: #FFFFFF;
      background-color: #ea3385;
      border: 1px solid #ea3385;
      text-transform: uppercase;
      padding: 5px 16px;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 600;
      &:disabled {
        cursor: not-allowed;
        background-color: #535565;
        border-color: #535565;
        color: #788397;
      }
    }
  }
}

