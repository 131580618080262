@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.root {
    background-color: #ffffff !important;
    color: var(--container-color) !important;
    padding: 1rem !important;
    border-radius: 0.6rem !important;
    box-shadow: 0px 0px 50px rgba(21, 23, 44, 0.4) !important;
    font-size: 14px !important;
    z-index: 100;
}

.text {
    color: var(--container-color);
    margin-bottom: 0.6rem;
}

.arrow {
    &:before {
        border: 1px solid #e6e8ed;
        color: white;
    }
    color: white;
}

.title {
    font-weight: bold;
}

.buttonContainer {
    cursor: pointer;
    margin-top: 10px;
    height: 20px;
    &:hover {
        opacity: 0.8;
    }
}

.button {
    color: var(--selected-container-color);
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0;
    border: none;
    background-color: white;
    margin-right: 6px;
}

.arrow {
    padding-left: 0.6rem;
    height: 1.2rem;
}

.toolTipIcon {
    cursor: pointer;
    padding: 0.6rem;
    &:hover {
        opacity: 0.8;
    }
}
