@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.protectionManagementContainer {
    background-color: #282b3f;
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 950px;
    padding-bottom: 15px;
}
.protectionManagementHeader {
    display: flex;
    flex-direction: row;
    background-color: #14172c;
    width: 100%;
}
.protectionManagementTitle {
    display: flex;
    font-size: 18px;
    text-align: start;
    justify-content: space-between;
    padding: 16px 0px 13px 0px;
    padding-left: 17px;
}
.accountConnectionTitle {
    font-size: 18px;
    margin-top: auto;
    text-align: end;
    justify-content: space-between;
    padding: 10px 0px 15px 0px;
    padding-left: 429px;
}
.accountConnectionToggle {
    font-size: 18px;
    text-align: end;
    justify-content: space-between;
    padding: 10px;
    padding-left: 17px;
}

.protectionManagementContent {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px;

    &.messageContainer {
        height: 452px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding-top: 30px;
        font-size: 17px;

        .error {
            color: red;
        }
    }

    .leftColumn {
        display: flex;
        flex-flow: column nowrap;
        width: 220px;
        align-items: center;
        background-color: rgb(56, 56, 81);
        margin-right: 15px;

        .protectionFeaturesHeader {
            background: rgb(20, 23, 44);
            height: 40px;
            font-size: 12px;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0.12px;
            color: rgb(254, 0, 114);
            line-height: 40px;
            width: 100%;
            padding-left: 28px;
        }

        .protectionFeaturesBody {
            display: flex;
            flex-flow: column nowrap;
            flex: 1;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            .protectionFeatureToggle {
                min-height: 49px;
                padding: 5px 0;
                display: flex;
                width: 100%;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;

                &:nth-child(odd) {
                    background-color: rgb(56, 56, 81);
                }

                &:nth-child(even) {
                    background-color: rgb(33, 34, 60);
                }

                & > div {
                    padding-left: 5px;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: flex-start;
                }

                .info {
                    margin-right: 5px;
                }

                .protectionFeatureLabel {
                    font-size: 12px;
                    padding-left: 5px;
                }

                .protectionFeatureSwitch {
                }
            }

            .ipBanList {
                display: flex;
                flex-flow: column nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                padding: 0 5px;

                .ipListLabel {
                    font-size: 10px;
                    font-family: 'Open Sans', sans-serif;
                    line-height: 15px;
                    margin-bottom: 5px;
                    color: #d8d8d8;
                    width: 100%;
                }

                .ipList {
                    font-size: 12px;
                    height: 155px;
                    width: 100%;
                    resize: none;
                    background-color: #282b3f;
                    color: white;
                    border: 1px solid rgb(20, 23, 44);
                    outline: 0;

                    &::placeholder {
                        color: #fe0087;
                        font-size: 14px;
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .rightColumn {
        flex: 1;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-start;

        .tabsContainer {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            height: 36px;

            .tab {
                height: 36px;
                line-height: 36px;
                text-align: center;
                cursor: pointer;
                font-size: 12px;
                font-family: 'Open Sans', sans-serif;
                color: #fe0072;
                padding: 0 10px;

                &.selectedTab {
                    background: rgb(20, 23, 44);
                    color: white;
                    cursor: default;
                }
            }
        }

        .tabsBody {
            flex: 1;
            width: 100%;
            min-height: 350px;
            flex-flow: column nowrap;
            align-items: flex-start;
            justify-content: space-between;

            & > div {
                width: 100%;
            }
        }
    }
}

.footer {
    display: flex;
    gap: 8px !important;
    justify-content: center;
    text-align: center;
}

.loading {
    width: 100%;
}

.client-campaigns-header {
    height: 20px;
    padding: 10px;
    background: #14172c;
    font-size: 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;
    padding-left: 40px;
    font-family: 'Open Sans', sans-serif;
}

.client-campaigns-second-header {
    @extend .client-campaigns-header;
    border-bottom: 1px solid #383851;
    padding-top: 0px;
}

.client-campaigns-header div {
    display: inline-block;
    vertical-align: top;
}

.header-label {
    color: #fe0072;
    margin-right: 10px;
}

.account-name {
    color: white;
    width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.account-id {
    color: white;
    margin-right: 3px;
}

.client-campaigns-table-wrapper {
    height: 300px;
}

.back-to-clients {
    color: rgb(254, 0, 114);
    margin-right: 5px;
    position: absolute;
    right: 10px;
    cursor: pointer;
}

.back-to-clients:hover {
    color: white;
}

.full-protection {
    position: absolute;
    right: 69px;
}

.full-protection-label {
    font-family: 'Open Sans', sans-serif;
    color: #fe0072;
    margin-right: 13px;
    line-height: 12px;
}

.protection-mode-select {
    width: 140px;
    height: 25px;
}

.protection-mode-label {
    font-family: 'Open Sans', sans-serif;
    color: #fe0072;
    margin-left: 20px;
    margin-right: 10px;
}

.protected-header-cell-label {
    display: inline-block;
    vertical-align: top;
}

.protected-header-cell-checkbox {
    display: inline-block;
    vertical-align: middle;
}

.chip-size-small {
    height: 16px !important;
    background: #fe0072 !important;
    margin: 0 5px;
    position: relative !important;
    top: -1px !important;
}

.chip-label-small {
    padding-left: 6px !important;
    padding-right: 6px !important;
    font-size: 10px !important;
}

.protection-mode-desc {
    font-size: 10px;
    font-family: 'Open Sans', sans-serif;
    margin-left: 10px;
    width: 260px;
    color: #d8d8d8;
    white-space: normal;
}

.link {
    color: inherit;
    text-decoration: none;
}

.link:visited {
    color: inherit;
}

.link:hover {
    text-decoration: underline;
}

.clients-table-container {
    width: inherit;
    height: inherit;
}
