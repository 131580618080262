@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/colors';

.landingPages {
    margin: 0;
}

.skeletonLoader {
    min-height: 220px;
}

.url {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.urlText {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
}

.invalidClicks {
    white-space: nowrap;
    gap: 1rem;
    display: flex;
    flex: 1;
}

.scaleBar {
    width: 3rem;
}

.icon {
    width: 2rem !important;
    height: 2rem !important;
}

.allVisits {
    flex: 1;
    /* min-width: 90px; */
}
