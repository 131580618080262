.container {
    margin-left: 15px;
}

.node {
    display: inline;
    margin: 5px;
    /* cursor: pointer; */
}

.selected {
    color:crimson;
    font-weight:bold;
}

.node:hover {
    /* color: crimson; */
}

.addInput {
    width: 180px;
    height: 30px;
}

.toggle-container {
    display: inline-block;
    cursor: pointer;
}

.toggle {
    top: 10px;
    margin: 5px;
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

.toggle:before, .toggle:after {
    content: "";
    position: absolute;
    background-color: crimson;
    transition: transform 0.25s ease-out;
}

.toggle:before {

    top: 0;
    left: 50%;
    width: 4px;
    height: 100%;
    margin-left: -2px;
}

.toggle:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    margin-top: -2px;
}

.toggle.open {
    cursor: pointer;
}

.toggle.open:before {
    transform: rotate(90deg);
}

.toggle.open:after {
    transform: rotate(180deg);
}
