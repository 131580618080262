@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
  position: relative;
  width: 465px;
  .input {
    outline: none;
    width: 100%;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    background-color:  #282A3F;
    font-family: Open Sans;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 6px 12px;
    box-sizing: border-box;
    &::placeholder {
      color: #696a79;
      font-size: 14px;
    }
    &:focus {
      border: 1px solid #EA3385;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active{
      -webkit-box-shadow: 0 0 0 30px #282a3f inset !important;
      -webkit-text-fill-color: #FFFFFF !important;
    }
    &.small {
      width: 400px;
    }
  }

  .eyeIcon {
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 7px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

@media only screen and (max-width: $screenLG) {
  .container {
    .input {
      height: 30px;
    }
  }
}