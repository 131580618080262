.tableContainer {
    padding: 1rem;
    box-sizing: border-box;
}
.noDataDisplay {
    display: flex;
    align-items: center;
    justify-content: center;
}

.tableCellBody {
    display: flex;
    gap: 0.8rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 19px;
}

.tableCellHeader {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 19px;
    white-space: nowrap;
}

.tableRowHeader {
    border-bottom: 0.5px solid #ffff;
}

.tableCell {
    border-bottom: inherit !important;
    padding: 1rem !important;
}

.tableRow {
    height: 18px;
    border-bottom: 0.5px solid #282a3f;
}

.tableRow:last-child {
    border-bottom: none;
}

.value {
    display: flex;
    gap: 0.3rem;
}
