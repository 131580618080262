.container {
    background-color: white !important;
    color: var(--container-color) !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    margin: 0 !important;
}

.container:not(:last-child) {
    border-bottom: 0.1rem solid var(--border-seperator-color-light-darker) !important;
}

.expand-icon {
    color: var(--container-color) !important;
}

.container:not(:last-child) {
    border-bottom: 0.1rem solid var(--border-seperator-color-light-darker) !important;
}

.title-container {
    padding: 0 !important;
    margin: 0 !important;
}
