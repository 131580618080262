@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.root {
    position: relative;
}
.selectInput {
    width: 200px !important;
    border: 1px solid #ffffff !important;
    border-radius: 2px !important;
    font-size: 14px !important;
    padding: 3px 12px !important;
    display: flex !important;
    align-items: center !important;
    &:focus {
        border: 1px solid #ea3385 !important;
    }
    &.small {
        width: 140px !important;
    }
    &.large {
        width: 240px !important;
    }
}
.item {
    font-size: 14px !important;
}
