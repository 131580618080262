@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/colors';

.container {
    position: relative;
    height: 30px;
    width: 250px;
    margin-left: 16px;

    &.channels {
        width: 390px;
        margin-top: 8px;
        margin-left: 2px;
    }

    .selectInput {
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px solid #fff;
        border-radius: 2px;
        height: 33px;
        cursor: pointer;

        &.active {
            border: 1px solid #ea3385;
        }

        &.error {
            border-color: red;
        }

        .placeholder {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.3);
        }

        .tick {
            margin-left: auto;
            padding-right: 8px;
            cursor: pointer;
        }

        .disableArrow {
            margin-left: auto;
            padding-right: 8px;
            cursor: unset;
        }

        .label {
            font-size: 14px;
            font-weight: 600;
            display: flex;
            margin-left: 12px;

            .listOfSelected {
                align-items: center;
                margin-left: 3px;
                white-space: nowrap;
                max-width: 150px;
                display: flex;

                .selectedOneItem {
                    font-size: 14px;
                    margin-right: 4px;
                    font-weight: 400;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }

    .dropdown {
        max-height: 300px;
        overflow: auto;
        position: absolute;
        z-index: 1;
        background-color: #282a3f;
        top: calc(100% - -8px);
        left: 0;
        width: 100%;
        border: solid 1px #fff;
        border-radius: 2px;

        .items {
            padding: 2px 8px;
            display: flex;
            flex-flow: column;

            .searchBox {
                display: flex;
                width: 100%;
                height: 30px;
                margin-top: 8px;
                margin-bottom: 8px;
                border: 1px solid #ffffff;
                box-sizing: border-box;
                border-radius: 2px;
                background-color: transparent;
                padding: 3px 7px;

                .input {
                    margin-left: 5px;
                    outline: none;
                    border: none;
                    width: 100%;
                    background-color: transparent;
                    color: #ffffff;
                    font-size: 14px;

                    &::placeholder {
                        font-size: 14px;
                        color: rgba(255, 255, 255, 0.3);
                    }
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    .container {
        width: 230px !important;
    }
    .listOfSelected {
        max-width: 120px !important;
    }
}
