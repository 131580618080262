@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.root {
    min-height: 550px;
    background-color: #282b3f;

    .row div {
        display: inline-block;
    }

    .content {
        text-align: left;
        padding: 30px;
        position: relative;
        width: auto;
    }

    .footer {
        position: absolute;
        bottom: -30px;
        width: 840px;
        text-align: center;
        display: flex !important;
        gap: 1rem;

        input {
            height: 30px;
            padding: 0px 5px 0px 5px !important;
            font-size: 18px !important;
            max-width: 150px;
        }
    }

    .completed {
        font-family: 'Open Sans';
        font-size: 31px;
        padding-top: 100px;
        margin: 45px;
        height: 160px;
    }

    .BtnContainer {
        height: 24px;

        input {
            font-size: 12px;
            height: 100%;
            outline: none;
            width: 150px;
        }
    }
}
