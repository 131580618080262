@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.component {
    margin: 0;
}

.outlined ~ fieldset {
    border-color: #fe0072 !important;
    border-width: 4px !important;
}

.selectRoot {
    width: 100%;
}

.inputRoot {
    display: flex;
    height: 24px !important;
}

.selectPaper {
    max-height: 250px !important;
}
