@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.container {
  position: fixed;
  width: calc(100% - 238px);
  padding: 0 16px;
  height: calc(100vh - 200px);
}

.root {
  height: 100%;
  @include flexColumn(flex-start, space-between);
  flex: 1;
}

.content {
  flex: 1;
}

.indicator {
  background: #ea3385 !important;
}

.flex-container {
  background: #383852;
  box-shadow: 0px 4px 30px #15172C;
  height: 100% !important;
  justify-content: center;
  flex: 1;
}

.header {
  top: 0;
  display: flex;
  justify-content: center;
  position: sticky;
  background: #383852;
  box-shadow: 0px 12px 12px #15172c;
  z-index: 1;
}

.tab {
  background: transparent !important;
  color: white !important;
  font-size: 1.8rem;
  font-weight: 600;
  align-items: flex-end !important;
  max-width: initial !important;
}

.wrapper {
  display: flex;
  flex-direction: row !important;
  font-weight: 600;
  justify-content: center !important;
  font-size: 1.8rem;
  gap: 1rem;
}
//@media only screen and (max-width: $screenLG) {
//  .container {
//    width: 1356px;
//  }
//}
//
//@media only screen and (max-width: $screenMD) {
//  .container {
//    width: 1151px;
//  }
//}
//
//@media only screen and (max-width: $screenSM){
//  .container {
//    width: 1076px;
//  }
//}