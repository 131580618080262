@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.container {
    display: flex;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 16px;
    text-transform: capitalize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #14172c;
}

/* Hide the browser's default checkbox */
.container input,
.option {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    background: transparent;
}

/* Create a custom checkbox */
.option .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #14172c;
    padding-right: 4px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #14172c;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #14172c;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.search-input {
    width: 100%;
    padding: 3px 7px;
    text-align: start;
    border-radius: 2px;
    color: white;
    background-color: transparent;
}

.multiSelectWrapper {
    display: flex;
    width: 100%;
    background-color: #14172c;
}
.multiSelectWrapper.multi-select {
    flex-grow: 1;
}
.multiSelectWrapper.multi-select > div:first-child {
    height: 30px;
}

.multiSelectSelected {
    border: '1px solid white';
    border-radius: 2;
    padding: '0px 3px 0px 3px';
}
.customMenuList {
    overflow-y: scroll;
    max-height: 220px;
}
