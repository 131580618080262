@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/colors';

.countries {
    grid-column: 7/14;
    grid-row: 2;
    margin: 0;
}

.skeletonLoader {
    min-height: 320px;
}

.heatMap {
    grid-column: 14/22;
    grid-row: 2;
    margin: 0;
}

.scaleBar {
    width: 50px;
    justify-self: end;
}

.country {
    display: flex;
    align-items: center;
    gap: 8px;
}

.countryName {
    width: 100%;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.countryFlag {
    display: flex;
    gap: 8px;
    align-items: center;
}
.allVisits {
    /* min-width: 90px; */
}
.invalidClicks {
    display: flex;
    flex: 1;
    gap: 16px;
}
