.title {
    margin-top: 2.5rem;
    font-size: 2.4rem;
    font-stretch: 'normal';
    font-style: 'normal';
    line-height: 'normal';
    letter-spacing: 0.024rem;
    text-align: 'left';
    align-self: 'flex-start';
    color: '#fffeff';
    font-family: inherit;
}
