.container {
    height: 100%;
    overflow: auto;
    width: 100%;
    background-color: #282a40;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 100%;
}

.container iframe {
    width: 100%;
    height: 100%;
}

.logo-container {
    text-align: center;
    margin: 17px 0 41px;
}

.logout-container {
    bottom: 0;
    position: absolute;
    text-align: center;
    align-self: center;
    margin-bottom: 20px;
}

.snackbar-root {
    bottom: 0;
}

.snackbar-content-root {
    background: rgb(20, 23, 44) !important;
    color: white !important;
    border-radius: 0 !important;
    border: 1px solid #fe0072;
}

.snackbar-content-message {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
}
