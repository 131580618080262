@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.formRoot {
  white-space: nowrap;
  margin-left: unset !important;
  margin-right: unset !important;
}

.label {
  font-size: 14px !important;
  font-weight: 400;
  font-family: inherit;
  margin-left: 6px !important;
}

.root {
  margin-right: 6px;
  margin-left: 0;
  padding: 7px 0 !important;
  color: #fe0072 !important;
  &.checked {
    color: #383852;
    background-color: #EA3385;
  }
}