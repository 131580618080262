@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/colors';

.sortLabel {
    &:global(.MuiFab-root) {
        &:before {
            visibility: visible;
        }

        &:hover:before {
            visibility: visible;
        }
    }
}

.deleteIcon {
    &:global(.MuiButton-text) {
        color: #fe0072;
    }
    display: flex;
    justify-content: flex-end;
}

.rowColorOne {
    &:global(.MuiTableRow-root) {
        background-color: #383851;
    }
}

.rowColorTwo {
    &:global(.MuiTableRow-root) {
        height: 36px;
        background-color: rgb(33, 34, 60);
    }
}

.addIcon {
    &:global(.MuiFab-root) {
        height: 40px;
        width: 40px;
        background-color: #282b3f;
        margin-top: 10px;
        left: 93%;
        cursor: pointer;

        :global(.MuiSvgIcon-root) {
            color: #fe0072;
        }
    }
}

.deleteButton {
    margin-left: 10px;
    cursor: pointer;
}

.cellContainer {
    &:global(.MuiTableCell-root) {
        padding: 0px;
    }
}

.selectInput {
    width: 200px !important;
    border: 1px solid #ffffff !important;
    border-radius: 2px !important;
    font-size: 14px !important;
    padding: 3px 12px !important;
    display: flex !important;
    color: #fe0072 !important;
    align-items: center !important;

    &:focus {
        color: #fe0072 !important;
        border: 1px solid #ea3385 !important;
    }

    &.small {
        width: 140px !important;
    }
}

.item {
    font-size: 14px !important;
}

.pixelIdInput {
    &:global(.MuiInput-underline) {
        &:before {
            content: none !important;
        }
    }

    color: #fe0072 !important;
    background-color: #14172c;
    height: 23px !important;
    padding: 0 5px;
    width: 200px !important;
    border-radius: 2px;
}

.headers {
    &:global(.MuiTableRow-root) {
        &:first-child th {
            background-color: #383851 !important;
            font-size: 12px;
            color: rgb(254, 0, 114);
        }
    }
}

.selectTagId {
    &:global(.MuiInput-underline .MuiSelect-selectMenu) {
        &:before {
            border: none !important;
            content: none !important;
        }

        padding: 8px;
        border: none !important;
    }

    &::before {
        border: none !important;
        content: none !important;
    }

    border: none !important;
    background-color: #282b3f;
    height: 23px !important;
    border-radius: 2px;
}