@import '../../../../../../../styles/mixins';
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/colors';

.tableCellWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.fieldInput {
    background-color: #14172c;
    border: none;
    width: 80%;
    height: 20px;
    line-height: 20px;
    color: #fe0072;

    &:focus {
        outline: none;
    }
}

.addIcon {
    &:global(.MuiFab-root) {
        height: 40px;
        width: 40px;

        background-color: #282b3f;
        align-self: flex-end;
        margin-top: 15px;

        :global(.MuiSvgIcon-root) {
            color: #fe0072;
        }
    }
}

.deleteButton {
    cursor: pointer;
    color: #fe0072;
}
