@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
  width: 600px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  .header {
    display: flex;
    padding: 24px;
    align-items: center;
    justify-content: space-between;
    height: 73px;
    background: #282A3F;
    box-shadow: 0 4px 20px #15172C;
    .title {
      font-family: Open Sans;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      text-transform: uppercase;
    }
    svg {
      cursor: pointer;
    }
  }
  .body {
    padding: 24px;
  }
  .footer {
    padding:24px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    border-top: 1px solid #15172C;
    .text {
      font-family: Open Sans;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
      color: #EA3385;
      cursor: pointer;
    }
    .button {
      height: 30px;
      margin-left: 14px;
      font-size: 14px;
      border-radius: 2px;
    }
  }
}

@media only screen and (max-width: $screenLG) {
  .container {
    width: 498px;
  }
}