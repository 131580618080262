@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  .title {
    font-family: inherit;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    text-transform: uppercase;
  }
  .options {
    display: flex;
    .inputItem {
      align-items: center;
      position: relative;
      display: flex;
      height: 36px;
      width: 300px;
      padding: 3px 10px;
      box-sizing: border-box;
      border-radius: 2px;
      border: 1px solid #FFFFFF;
      .input {
        border: none;
        margin-left: 5px;
        color: #FFFFFF;
        font-family: Open Sans;
        font-size: 14px;
        line-height: 19px;
        outline: none;
        background: none;
        &::placeholder {
          color: rgba(255, 255, 255, 0.3);
        }
      }
      .clear {
        position: absolute;
        right: 10px;
        cursor: pointer;
      }
      &:focus-within {
        border: 1px solid #EA3385;
      }
    }
    .button {
      height: 30px;
      margin-left: 14px;
      font-size: 14px;
      border-radius: 2px;
      svg {
        margin-right: 4px;
        width: 17px;
        height: 17px;
      }
      img {
        margin-right: 9px;
      }
      &:disabled {
        border: 1px solid #788397;
        background-color: #788397;
        cursor: not-allowed;
      }
    }
  }
}

@media only screen and (max-width: $screenLG) {
  .container {
    .options {
      .inputItem {
        height: 30px;
        svg {
          top: 6px;
        }
        .input {
          height: 30px;
        }
      }
    }
  }
}