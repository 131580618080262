@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.root {
    display: flex;
    flex-direction: column;

    .setUpSettings {
        height: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 18px;
        padding: 22px 0;
        box-sizing: border-box;
        background-color: $paperColor;
        @include rounded;

        .description {
            font-size: 14px;
            text-align: center;
        }

        .setUpTitle {
            font-size: 24px;
            font-weight: 600;
        }
    }
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
