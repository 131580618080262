@import '../../styles/mixins';
@import '../../styles/variables';
@import '../../styles/colors';

.container {
    height: calc(100vh - 60px);
    margin: 0 auto;
    justify-content: center;
    display: flex;
    flex-flow: column;

    .title {
        text-align: left;
        font-family: 'Avenir';
        font-weight: 500;
        font-size: 20px;
    }

    .productsList {
        margin-top: 16px;

        .row {
            margin-bottom: 20px;
            display: flex;
            justify-content: center;

            .box {
                margin-right: 20px;
                width: 40rem;
                height: 34rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-flow: column;
                background-color: #383852;
                box-shadow: 0 0 50px rgba(21, 23, 44, 0.4);
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    border: 1px solid #ea3385;
                }

                &.disabled {
                    cursor: unset;
                }

                .top {
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 3rem;

                    img {
                        height: 8rem;
                    }

                    .label {
                        margin-top: 2.9rem;
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 2rem;
                        line-height: 3rem;

                        &.disabled {
                            color: #788397;
                        }
                    }

                    .description {
                        text-align: center;
                        max-width: 400px;
                        margin-top: 12px;
                        font-weight: 300;
                        font-size: 1.1rem;
                        line-height: 1.5rem;
                        padding: 0.5rem;

                        &.disabled {
                            cursor: unset;
                        }
                    }
                }

                .learnMore {
                    display: flex;
                    align-items: center;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    margin-bottom: 20px;

                    .help {
                        background: rgba(255, 255, 255, 0.2);
                        border-radius: 2px;
                        height: 18px;
                        width: 18px;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        margin-right: 8px;
                    }

                    .contactUs {
                        cursor: pointer;
                        margin-left: 6px;
                        color: #ea3385;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
