@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.datePickerInput {
    // background: #383851;
    // height: 4.1rem;
    // width: -webkit-fill-available;
    // color: inherit;
    // font-style: inherit;
    // font-size: inherit;
    // font-family: inherit;
    // padding: 0.8rem 1.2rem 0.8rem 1.2rem;
    // margin-bottom: 0.5rem;
    // border: none;
    width: 100% !important;
}

.datePickerWrapper {
    margin-left: 1rem;
}

.datePickerInput:hover {
    // border: 1px solid #fff;
}

.buttonWrapper {
    @include flexRow(center);
    @include paddedContainer(1.6rem 0);
    margin-left: 1rem;
}
