@import '../../../../../../styles/mixins';
@import '../../../../../../styles/variables';
@import '../../../../../../styles/colors';

.container {
    margin-bottom: 24px;
    .label {
        display: flex;
        margin-bottom: 8px;
        align-items: center;
        font-family: Open Sans;
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;
        margin-top: 7px;
    }
    .options {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        .row {
            display: flex;
        }
        .text {
            font-family: Open Sans;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 16px;
            margin-top: 4px;
        }
    }
}
.checkbox {
    padding: 0 0 0 4px !important;
}

.checkboxContainer {
    display: flex;
    gap: 8px;
}
.platforms {
    display: flex;
    margin: 16px 0;
    align-items: center;
    gap: 8px;
    font-size: 16px;
}
.platformsTitle {
    padding-right: 8px;
    position: relative;

    &:after {
        content: '';
        border-right: 1px solid white;
        position: absolute;
        top: 30%;
        right: -4px;
        height: 16px;
    }
}
.toggle {
    height: 29px;
    border-radius: 0px 2px 2px 0px;
    position: relative;
    button {
        text-transform: capitalize;
        background-color: #35384b;
        padding: 5px, 16px, 5px, 16px;
        width: 113px;
    }
    button[aria-pressed='true'] {
        background-color: white;
        color: #e12468;
    }
}
