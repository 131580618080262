@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.container {
  width: 100%;
  height: calc(100vh - 290px);
  padding: 0 16px;
  margin-top: 20px;
  background: #383852;
  border-radius: 6px;
  .tableBody {
    overflow: auto;
    overflow-x: hidden;
    max-height: 600px;
    margin-top: 4px;
    .rows {
      padding: 0 16px;
      .row {
        display: flex;
        align-items: center;
        height: 43px;
        width: 100%;
        font-family: Open Sans;
        font-size: 14px;
        line-height: 19px;
        border-bottom: 1px solid #282A3F;
        .item {
          display: flex;
          align-items: center;
          flex:1;
          .icons {
            display: flex;
            .download {
              cursor: pointer;
              margin-left: 10px;
            }
          }
          &.status {
            display: flex;
            align-items: center;
            .icon {
              margin-right: 5px;
            }
            &.Completed,
            &.CompletedNoData {
              color: #2ED15E;
            }
            &.Failed {
              color: #FF4E4E;
            }
            &.Cancelled {
              color: #788397;
            }
            &.Running {
              color: #2094FF;
            }
            &.Queued {
              color: #FEAB45;
            }
          }
          &:nth-child(1) {
            flex: 5 1;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 250px;
            display: inline-block;
            margin-right: 36px;
          }
          &:nth-child(2) {
            flex: 4;
          }
          &:nth-child(3) {
            flex: 5;
          }
          &:nth-child(4) {
            flex: 5;
          }
          &:nth-child(5) {
            flex:4;
          }
          &:nth-child(6) {
            flex:3;
            min-width: 88px;
          }
          &:nth-child(7){
            flex: 2;
            justify-content: flex-end;
            display: flex;
          }
        }
        .scheduled {
          display: flex;

          .icon {
            cursor: pointer;
            margin-right: 15px;
          }
          .failedIcon {
            margin-left: 8px;
          }
          &:nth-child(1) {
            flex: 3;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 210px;
            display: inline-block;
            margin-right: 22px;
          }
          &:nth-child(2) {
            flex: 3;
          }
          &:nth-child(3) {
            flex: 4;
          }
          &:nth-child(4) {
            flex: 3;
          }
          &:nth-child(5) {
            flex:2;
          }
          &:nth-child(6) {
            flex:4;
          }
          &:nth-child(7){
            flex: 2;
            justify-content: flex-end;
            display: flex;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $screenLG) {
  .container {
    .tableBody {
      max-height: 604px;
      .rows {
        .row {
          .item {
            &:nth-child(1) {
              max-width: 211px;
              margin-right: 25px;
            }
          }
          .scheduled {
            &:nth-child(1) {
              max-width: 168px;
              margin-right: 22px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $screenMD) {
  .container {
    .tableBody {
      max-height: 470px;
      .rows {
        .row {
          .item {
            &:nth-child(1) {
              max-width: 180px;
              margin-right: 20px;
            }
          }
          .scheduled {
            &:nth-child(1) {
              max-width: 150px;
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $screenSM) {
  .container {
    .tableBody {
      max-height: 440px;
      .rows {
        .row {
          .item {
            &:nth-child(1) {
              max-width: 160px;
              margin-right: 26px;
            }
          }
          .scheduled {
            &:nth-child(1) {
              max-width: 140px;
              margin-right: 12px;
            }
          }
        }
      }
    }
  }
}