@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.container {
    float: left;
    width: 100%;
    font-size: 2.4rem;
    color: #fff;
    text-align: initial;
    margin-top: 1.1rem;
    margin-left: 2rem;

    .title {
        font-family: inherit;
        margin-bottom: 1rem;
    }
}

.historyItem {
    width: 100%;
    background-color: #14172c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    padding-right: 0;
    margin-bottom: 1.2rem;
    border-radius: 2px;

    .reportParamsText {
        overflow: hidden;
        font-weight: bold;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        text-transform: capitalize;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        -webkit-box-pack: center;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .iconsContainer {
        display: flex;
        margin-right: 0.7rem;

        .iconButton {
            padding: 0.3rem;
            cursor: pointer;
            color: #fe0072;
        }
    }
}
