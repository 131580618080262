@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';
@import '../../../../../styles/colors';

.component {
    width: 100%;
    overflow: hidden;
    @include flexColumn();
    /* margin: 2rem 0; */
}

.platformsRow {
    width: 100%;
    display: flex;
    gap: 1rem;
    @include flexRow();
}

.filterRow {
    margin-top: 3rem;
    background-color: #383852;
    width: 100%;
    padding: 1rem;
    justify-content: space-between;
    font-size: 1.3rem !important;
    @include flexRow();
    @include rounded;
    .content {
        @include flexRow();
        flex: 1;
        gap: 1rem;
        white-space: nowrap;
        .select {
            min-width: 15rem;
            max-width: 15rem;
        }
    }
    .button {
        // height: 36px;
    }
}

.inputContainer {
    min-width: 16rem;
}

.policyToggleOff {
    min-width: 16rem;
    max-width: 18rem;
    font-size: 10pt !important;
    gap: 0.4rem;
}

.lastUpdated {
    display: flex;
    gap: 6px;
    align-items: center;
}

.chosenDates {
    background: gray;
    font-size: 1.1rem;
    margin-right: 5px;
}
