@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '../../../styles/colors';

.row {
    display: flex;
    align-items: center;

    .button {
        color: $primaryColor;
        cursor: pointer;
        text-transform: uppercase;
    }
}